// Changes in MainPanel.js

import React, { useState, useEffect, useRef } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AIPanel from "./AIPanel";
import NodeResourcesPanel from "./NodeResourcesPanel";
import MemoryPalaceStationsPanel from "./MemoryPalaceStationsPanel";
import MemoryPalaceStationEditPanel from "./MemoryPalaceStationEditPanel";
import MemoryPalaceEditPanel from "./MemoryPalaceEditPanel";
import LatestNewsPanel from "./LatestNewsPanel";
import MindRaiseUni from "./MindRaiseUni";
import AIMindPanel from "./AIMindPanel";
import MemoryPalaceSlideshowPanel from "./MemoryPalaceSlideshowPanel";
import TestMemoryPalaceStationsPanel from "./TestMemoryPalaceStationsPanel";
import FlowDiagramPanel from "./FlowDiagramPanel";
import { BASE_URL } from "./config";
import SelectedResourcePanel from "./SelectedResourcePanel";
import { isEqual } from "lodash";
import FeatureBase from "./FeatureBase";
import { apiFetch, fetchWithValidToken } from "./utils";
import UniversityPanel from "./UniversityPanel";
import GenerateImagePanel from "./GenerateImagePanel";

const MainPanel = ({
  activeTab,
  userId,
  chatHistory,
  selectedNode,
  selectedMemoryPalaceId,
  memoryPalaces,
  setChatList,
  setSelectedChatId,
  selectedChatId,
  isExpanded,
  setIsExpanded,
  isRightPanelVisible,
  isLoggedIn,
  setIsLoggedIn,
  editingMemoryPalace,
  setEditingMemoryPalace,
  stop,
  start,
  setUseBrowserTTS,
  addToQueue,
  cancelAll,
  isListening,
  useBrowserTTS,
  textQueue,
  audioQueue,
  isPlaying,
  audioContext,
  latestAudioBuffer,
  analyser,
  testMode,
  setTestMode,
  setSelectedModel,
  selectedModel,
  user,
  inputText,
  setInputText,
  messages,
  setMessages,
  assistantId,
  setAssistantId,
}) => {
  const [rightHandTab, setRightHandTab] = useState("news");
  const [editStation, setEditStation] = useState(null);
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [stations, setStations] = useState([]);
  const [isExpandedView, setIsExpandedView] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [maxWidth, setMaxWidth] = useState("1000px");
  const AIPanelRef = useRef(null);

  const selectedMemoryPalace = memoryPalaces.find(
    (palace) => palace.id === selectedMemoryPalaceId
  );
  const selectedMemoryPalaceName = selectedMemoryPalace
    ? selectedMemoryPalace.name.toUpperCase()
    : "";
  const memoryPalaceDescription = selectedMemoryPalace
    ? selectedMemoryPalace.description
    : "";
  const memoryPalacePhoto = selectedMemoryPalace
    ? selectedMemoryPalace.photo
    : "";
  const [panelDirection, setPanelDirection] = useState("horizontal");

  useEffect(() => {
    handleResizeEnd(50);
  }, []);

  // Create a hook effect to handle the resizing
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setPanelDirection("vertical");
      } else {
        setPanelDirection("horizontal");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on component mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Make sure the effect runs only once on mount and unmount

  useEffect(() => {
    if (selectedMemoryPalaceId) {
      fetchStations();
    }
  }, [selectedMemoryPalaceId]);

  const fetchStations = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/memory_palaces/${selectedMemoryPalaceId}/stations`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setStations(data);
    } catch (error) {
      console.error("Error fetching stations:", error);
    }
  };

  const handleDividerDoubleClick = () => {
    setIsExpandedView(!isExpandedView);
  };

  const handleSaveStation = (updatedStation) => {
    setStations((prevStations) =>
      prevStations.map((station) =>
        station.id === updatedStation.id ? updatedStation : station
      )
    );
    setEditStation(null);
  };

  const handleSaveMemoryPalace = (updatedMemory) => {
    const updatedMemoryPalaces = memoryPalaces.map((palace) =>
      palace.id === updatedMemory.id ? updatedMemory : palace
    );
    setMemoryPalaces(updatedMemoryPalaces);
    setEditingMemoryPalace(null);
  };

  const handleSelectResource = (resource) => {
    setSelectedResource(resource);
    setRightHandTab("selectedResource");
  };

  const handleResizeEnd = (size) => {
    // Your code to be executed after resizing ends
    if (AIPanelRef.current != null) {
      const width = (AIPanelRef.current.offsetWidth / 100) * size - 80;
      setMaxWidth(width);
    }
    // Call your other necessary code here
  };

  return (
    <div
      id="main-panel"
      className="flex flex-1 ml-3 p-5 shadow-lg rounded-[25px] bg-black border-t border-gray-500 main-panel"
      ref={AIPanelRef}
    >
      <PanelGroup direction={panelDirection}>
        <Panel
          className="flex flex-1 left-panel-main"
          onResize={handleResizeEnd}
        >
          <div id="left-panel-main" className="flex-grow flex-1">
            {activeTab === "ai" && (
              <AIPanel
                userId={userId}
                chatHistory={chatHistory}
                setChatList={setChatList}
                selectedChatId={selectedChatId}
                setSelectedChatId={setSelectedChatId}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                stop={stop}
                start={start}
                setUseBrowserTTS={setUseBrowserTTS}
                addToQueue={addToQueue}
                cancelAll={cancelAll}
                isListening={isListening}
                useBrowserTTS={useBrowserTTS}
                selectedModel={selectedModel}
                user={user}
                inputText={inputText}
                setInputText={setInputText}
                messages={messages}
                setMessages={setMessages}
                assistantId={assistantId}
                setAssistantId={setAssistantId}
                maxWidth={maxWidth}
                setMaxWidth={setMaxWidth}
              />
            )}
            {activeTab === "knowledge" &&
              selectedNode &&
              selectedNode.node_type === "branch" && (
                <NodeResourcesPanel
                  node={selectedNode}
                  onSelectResource={handleSelectResource}
                />
              )}
            {activeTab === "flow" && <FlowDiagramPanel />}
            {activeTab === "image" && <GenerateImagePanel />}
            {activeTab === "memory" &&
              selectedMemoryPalaceId &&
              !editStation &&
              !editingMemoryPalace &&
              (showSlideshow ? (
                <MemoryPalaceSlideshowPanel
                  stations={stations}
                  memoryPalaceName={selectedMemoryPalaceName}
                  onReturn={() => setShowSlideshow(false)}
                />
              ) : testMode ? (
                <TestMemoryPalaceStationsPanel
                  memoryPalaceId={selectedMemoryPalaceId}
                  memoryPalaceName={selectedMemoryPalaceName}
                  memoryPalaceDescription={memoryPalaceDescription}
                  memoryPalacePhoto={memoryPalacePhoto}
                  testMode={testMode}
                  setTestMode={setTestMode}
                />
              ) : (
                <MemoryPalaceStationsPanel
                  memoryPalaceId={selectedMemoryPalaceId}
                  memoryPalaceName={selectedMemoryPalaceName}
                  memoryPalaceDescription={memoryPalaceDescription}
                  memoryPalacePhoto={memoryPalacePhoto}
                  setEditStation={setEditStation}
                  setStations={setStations}
                  onShowSlideshow={() => setShowSlideshow(true)}
                  stations={stations}
                />
              ))}
            {activeTab === "memory" &&
              selectedMemoryPalaceId &&
              editStation && (
                <MemoryPalaceStationEditPanel
                  station={editStation}
                  onSave={handleSaveStation}
                  onCancel={() => setEditStation(null)}
                />
              )}
            {activeTab === "memory" && editingMemoryPalace && (
              <MemoryPalaceEditPanel
                memoryPalace={editingMemoryPalace}
                onSave={handleSaveMemoryPalace}
                onCancel={() => setEditingMemoryPalace(null)}
              />
            )}
          </div>
        </Panel>
        <PanelResizeHandle
          className="divider"
          onDoubleClick={handleDividerDoubleClick}
        />
        <Panel
          className="flex flex-1 right-panel-main"
          style={{ display: isExpandedView ? "none" : "flex" }}
        >
          {isRightPanelVisible && (
            <div
              id="right-panel-main"
              className="flex-grow flex-1 tabbed-panel ml-3 latest-news-panel text-[#B7B7B7] bg-black text-white pl-1 h-full max-h-screen w-full flex flex-col mb-2 overflow-hidden"
            >
              <div
                id="tabs"
                className="flex mb-5 news-tab border-t border-r border-gray-400 shadow-glow p-4 rounded-lg"
              >
                <div
                  id="tabNews"
                  className={
                    rightHandTab === "news"
                      ? "shadow-glow py-2 px-4 border-t border-r rounded-[10px] border-gray-500 text-white"
                      : "py-2 px-4 hover:bg-gray-800 cursor-pointer"
                  }
                  onClick={() => setRightHandTab("news")}
                >
                  <h2 className="text-[#B7B7B7] text-1xl font-lustria">NEWS</h2>
                </div>
                <div
                  className={
                    rightHandTab === "uni"
                      ? "py-2 px-4 border-t border-r rounded-[10px] shadow-glow border-gray-500 text-white uni-tab"
                      : "py-2 px-4 hover:bg-gray-800 cursor-pointer uni-tab"
                  }
                  onClick={() => setRightHandTab("uni")}
                >
                  <h2 className="text-[#B7B7B7] text-1xl font-lustria">
                    UNIVERSITY
                  </h2>
                </div>
                <div
                  style={{ display: "none" }}
                  className={
                    rightHandTab === "aimind"
                      ? "py-2 px-4 border-t border-r rounded-[10px] shadow-glow border-gray-500 text-white ai-tab"
                      : "py-2 px-4 hover:bg-gray-800 cursor-pointer ai-tab"
                  }
                  onClick={() => setRightHandTab("aimind")}
                >
                  <h2 className="text-[#B7B7B7] text-1xl font-lustria">
                    AI MIND
                  </h2>
                </div>
                <div
                  className={
                    rightHandTab === "selectedResource"
                      ? "py-2 px-4 border-t border-r rounded-[10px] shadow-glow border-gray-500 text-white resource-tab"
                      : "py-2 px-4 hover:bg-gray-800 cursor-pointer resource-tab"
                  }
                  onClick={() => setRightHandTab("selectedResource")}
                >
                  <h2 className="text-[#B7B7B7] text-1xl font-lustria">
                    RESOURCE
                  </h2>
                </div>
                <div
                  className={
                    rightHandTab === "feature"
                      ? "py-2 px-4 border-t border-r rounded-[10px] shadow-glow border-gray-500 text-white feedback-tab"
                      : "py-2 px-4 hover:bg-gray-800 cursor-pointer feedback-tab"
                  }
                  onClick={() => setRightHandTab("feature")}
                >
                  <h2 className="text-[#B7B7B7] text-1xl font-lustria">
                    FEEDBACK
                  </h2>
                </div>
              </div>
              <div className="overflow-hidden h-full">
                {rightHandTab === "news" && <LatestNewsPanel />}
                {rightHandTab === "feature" && <FeatureBase />}
                {rightHandTab === "uni" && <UniversityPanel />}
                {rightHandTab === "aimind" && (
                  <AIMindPanel
                    isPlaying={isPlaying}
                    audioContext={audioContext}
                    latestAudioBuffer={latestAudioBuffer}
                    analyser={analyser}
                    setUseBrowserTTS={setUseBrowserTTS}
                    useBrowserTTS={useBrowserTTS}
                    setSelectedModel={setSelectedModel}
                    selectedModel={selectedModel}
                    user={user}
                  />
                )}
                {rightHandTab === "selectedResource" && selectedResource && (
                  <SelectedResourcePanel resource={selectedResource} />
                )}
              </div>
            </div>
          )}
        </Panel>
      </PanelGroup>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  //all must be true
  const activeTabEqual = isEqual(prevProps.activeTab, nextProps.activeTab);
  const messagesEqual = isEqual(prevProps.messages, nextProps.messages);
  const selectedMemoryPalaceIdEqual = isEqual(
    prevProps.selectedMemoryPalaceId,
    nextProps.selectedMemoryPalaceId
  );
  const inputTextEqual = isEqual(prevProps.inputText, nextProps.inputText);

  return (
    activeTabEqual &&
    selectedMemoryPalaceIdEqual & messagesEqual &&
    inputTextEqual
  );
}

export default React.memo(MainPanel);
