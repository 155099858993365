// MemoryPalaceEditPanel.js
import React, { useState } from "react";
import "./index.css";
import { BASE_URL } from "./config";
import { fetchWithValidToken } from "./utils";

const MemoryPalaceEditPanel = ({ memoryPalace, onSave, onCancel }) => {
  const [name, setName] = useState(memoryPalace.name || "");
  const [description, setDescription] = useState(
    memoryPalace.description || ""
  );
  const [photoUrl, setPhotoUrl] = useState(memoryPalace.photo_url || "");
  const [photoFile, setPhotoFile] = useState(null);

  const handleSave = async () => {
    const updatedPalace = {
      ...memoryPalace,
      name,
      description,
      photo_url: photoUrl,
    };
    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/memory_palaces/${memoryPalace.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedPalace),
        }
      );

      onSave(updatedPalace); // Pass the updated palace back to the parent component
    } catch (error) {
      console.error("Error updating memory palace:", error);
    }
  };

  const handlePhotoUpload = async () => {
    if (!photoFile) return;

    const formData = new FormData();
    formData.append("photo", photoFile);

    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/memory_palaces/${memoryPalace.id}/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      setPhotoUrl(data.photo_url); // Assuming the response contains the updated palace with the new photo_url
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  const handleFileChange = (event) => {
    setPhotoFile(event.target.files[0]);
  };

  return (
    <div className="memory-palace-edit-panel text-[#B7B7B7] bg-[#0a0a0a] text-white p-4 rounded-lg border-t border-r border-gray-400 h-full max-h-screen shadow-glow flex flex-col mb-2 overflow-hidden">
      <h2 className="text-[#B7B7B7] text-1xl font-lustria">
        Edit Memory Palace
      </h2>
      <div className="flex-grow overflow-y-auto ai-panel pl-1 pr-1">
        <div className="flex flex-col space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Name
            </label>
            <input
              type="text"
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Description
            </label>
            <textarea
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Photo
            </label>
            <div className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md">
              {photoUrl ? (
                <img
                  src={photoUrl}
                  alt="memory palace"
                  style={{ width: "100px", height: "100px" }}
                />
              ) : (
                "No Photo"
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Upload Photo
            </label>
            <input
              type="file"
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              onChange={handleFileChange}
            />
            <button
              onClick={handlePhotoUpload}
              className="mt-2 py-2 px-4 bg-blue-600 rounded-md text-white"
            >
              Upload Photo
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-4 mt-4">
        <button
          onClick={onCancel}
          className="py-2 px-4 bg-red-600 rounded-md text-white"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="py-2 px-4 bg-green-600 rounded-md text-white"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MemoryPalaceEditPanel;
