import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { motion } from 'framer-motion';
import './LandingPage.css';
import logo from './assets/logo-small.png';
import bannerImage from './assets/prod.webp';
import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot3 from './assets/screenshot3.png';
import {
  FaBrain,
  FaBook,
  FaTasks,
  FaRobot,
  FaMicrophone,
  FaVolumeUp,
  FaCheckCircle,
  FaEnvelope,
  FaProjectDiagram,
  FaUserShield,
  FaChalkboardTeacher,
  FaHandsHelping,
  FaStar,
  FaEarlybirds,
  FaRocket,
  FaGifts,
  FaClock,
  FaVideo
} from 'react-icons/fa';
import { BASE_URL } from './config';
import Footer from './Footer';  
import CryptoJS from 'crypto-js';



const LandingPage = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const contactFormRef = useRef(null);


  function calculateTimeLeft() {
    const launchDate = new Date('2024-07-15T02:00:00');
    const now = new Date();
    const difference = launchDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  }
const goToPrivacyPolicy = () => {
  navigate('/privacy-policy');
};
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const goToLogin = () => {
    navigate('/login');
  };

  const goToSignUp = () => {
    navigate('/pricing');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const goToPioneers = () => {
    navigate('/pioneers');
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.landing-nav') && !event.target.closest('.hamburger')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const timerComponents = [];
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const toggleRegisterForm = () => {
    setIsRegisterFormOpen(!isRegisterFormOpen);
    if (isSubmitted) setIsSubmitted(false); // Reset submission status when reopening the form
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

const handleFormSubmit = async (e) => {
  e.preventDefault();
  if (selectedPlan.name === 'Free') {
  await handleFreePlanRegistration();
  return;
}
    const hashedEmail = CryptoJS.SHA256(formData.email).toString();
  const hashedPhoneNumber = ""; // Add phone number field and hash if available
  const hashedExternalId = ""; // Add external ID field and hash if available
  if (window.ttq) {
    ttq.track('ClickButton', {
      "contents": [
        {
          "content_id": "1005", // replace with actual content identifier
          "content_type": "email_list", // replace with actual content type
          "content_name": "Interest" // replace with actual content name
        }
      ],
      "value": "0", // replace with actual content value
      "currency": "GBP" // replace with actual content currency
    });
  }

  const response = await fetch(`${BASE_URL}/api/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: formData.name,
      email: formData.email,
    }),
  });

  if (response.ok) {
    console.log('Form submitted:', formData);
    setIsSubmitted(true); // Set submission status to true
        // GA Event Tracking
    if (window.gtag) {
      window.gtag('event', 'form_submission', {
        event_category: 'Registration',
        event_label: 'Register Your Interest',
        value: formData.email,
      });
    }
  } else {
    console.error('Error submitting form:', response.statusText);
  }
};


  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="landing-page panel-scroll">
      <header className="landing-header">
        <div className="title-container">
          <img src={logo} alt="Mind Raise Logo" className="logo" />
          <div style={{textAlign: 'left'}}>
            <h1 className="title">Mind Raise</h1>
            <p className="tagline">Unlock Your Full Potential with AI-Powered Productivity</p>
          </div>
        </div>
        <nav className={`landing-nav ${isMenuOpen ? 'open' : ''}`}>
          <button onClick={goToSignUp} className="nav-link">Sign Up</button>
          {/*<button onClick={goToPioneers} className="nav-link">Pioneers Page</button>*/}
          <button onClick={goToLogin} className="nav-link">Log In</button>

        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>

      <motion.section
        className="hero"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}  
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
      >
        <h2 className="hero-title">Join the Future of Productivity and Knowledge Management</h2>
        <div className="video-container" style={{"display":"none"}}>
          <ReactPlayer url="https://www.youtube.com/watch?v=8-H3MgC_Oc8" controls width="100%" />
        </div>
        <div className="image-container">
          <img src={bannerImage} alt="Mind Raise Banner" className="hero-image" />
        </div>
        <div className="hero-buttons">
          <button onClick={goToSignUp} className="cta-button primary-button">Join Today</button>
        </div>
      </motion.section>
      <motion.section
        className="features"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}  
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
      >
        <h2 className="section-title">Current Features</h2>
        <div className="feature-list">
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaBrain className="feature-icon" />
            <h3 className="feature-title">Memory Palace</h3>
            <p>Memorize anything with ease and ensure retention with our testing features. Comes with training videos and tutorials.</p>
          </motion.div>
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaBook className="feature-icon" />
            <h3 className="feature-title">Knowledge Management</h3>
            <p>Attach files, text, URLs, and more to a knowledge tree. Our AI can answer your questions using this reference.</p>
          </motion.div>
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaRobot className="feature-icon" />
            <h3 className="feature-title">AI Chatbot</h3>
            <p>Leverage OpenAI chatbots and other free models for assistance.</p>
          </motion.div>
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaCheckCircle className="feature-icon" />
            <h3 className="feature-title">AI Testing</h3>
            <p>Test your memory palaces, test yourself against the knowledge tree, and more.</p>
          </motion.div>
        </div>
      </motion.section>
      <motion.section
        className="features"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}  
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
      >
        <h2 className="section-title">Coming Soon Features</h2>
        <div className="feature-list">
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaTasks className="feature-icon" />
            <h3 className="feature-title">Project & Task Management</h3>
            <p>Organize your tasks and projects using the PARA technique. Includes calendars, reminders, and more.</p>
          </motion.div>
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaMicrophone className="feature-icon" />
            <h3 className="feature-title">Speech to Text</h3>
            <p>Convert speech to text using the Whisper AI model.</p>
          </motion.div>
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaVolumeUp className="feature-icon" />
            <h3 className="feature-title">Text to Speech</h3>
            <p>Communicate with the AI using OpenAI TTS.</p>
          </motion.div>
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaEnvelope className="feature-icon" />
            <h3 className="feature-title">Email Integration</h3>
            <p>Link your email to pull out important emails and create tasks.</p>
          </motion.div>
          <motion.div className="feature-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaProjectDiagram className="feature-icon" />
            <h3 className="feature-title">Flowchart/Mind Map</h3>
            <p>Create flowcharts and mind maps to organize your thoughts and tasks.</p>
          </motion.div>
        </div>
      </motion.section>
      <motion.section
        className="screenshots"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}  
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
      >
        <h2 className="section-title">Screenshots</h2>
        <Carousel showArrows={true} infiniteLoop={true} showThumbs={false}>
          <div>
            <img src={screenshot1} alt="Screenshot 1" />
          </div>
          <div>
            <img src={screenshot2} alt="Screenshot 2" />
          </div>
          <div>
            <img src={screenshot3} alt="Screenshot 3" />
          </div>
        </Carousel>
      </motion.section>

      <motion.section
        className="beta-info"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }} 
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
         style={{ display: 'none' }}
      >
        <h2 className="section-title">Join the Beta</h2>
        <div className="benefits">
          <motion.div className="benefit-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaUserShield className="benefit-icon" />
            <h3 className="benefit-title">Lifetime Access</h3>
            <p>Get free lifetime access to the platform (minus AI fees) by joining the beta.</p>
          </motion.div>
          <motion.div className="benefit-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaChalkboardTeacher className="benefit-icon" />
            <h3 className="benefit-title">Exclusive Webinar</h3>
            <p>Attend a 1-hour webinar with the CEO for an in-depth look at the software and onboarding process.</p>
          </motion.div>
          <motion.div className="benefit-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaHandsHelping className="benefit-icon" />
            <h3 className="benefit-title">Priority Support</h3>
            <p>Receive priority support via a dedicated messaging system.</p>
          </motion.div>
          <motion.div className="benefit-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaStar className="benefit-icon" />
            <h3 className="benefit-title">Recognition</h3>
            <p>Your name will be featured on the Pioneers Page as one of the first supporters.</p>
          </motion.div>
          <motion.div className="benefit-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaEarlybirds className="benefit-icon" />
            <h3 className="benefit-title">Early Bird Bonus</h3>
            <p>The first 100 signups will receive a 15-minute private Zoom call with the CEO.</p>
          </motion.div>
          <motion.div className="benefit-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaGifts className="benefit-icon" />
            <h3 className="benefit-title">Special Bonuses</h3>
            <p>Receive exclusive bonuses and perks as an early adopter.</p>
          </motion.div>
        </div>
        <div className="signup-info">
          <motion.div className="sign-up-benefit benefit-item" variants={sectionVariants} transition={{ duration: 0.5 }}>
            <FaRocket className="benefit-icon" />
            <h3 className="benefit-title">Secure Your Spot</h3>
            <p>Sign up before the launch date to secure your spot in the beta. The beta fee is $30, which includes $10 of AI tokens and free lifetime access to the platform (minus AI fees).</p>
          </motion.div>
          <button onClick={goToSignUp} className="sign-up-button cta-button">Secure Your Spot Now</button>
        </div>
      </motion.section>
      <motion.section
        className="registration-panel"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
        ref={contactFormRef} // Add ref to the contact form section
 style={{ display: 'none' }}
      >
        <h2 className="section-title">Register Your Interest</h2>
        <p className="registration-subtitle">Stay Updated with Our Latest News</p>
        <div className="registration-section">
          <p className="registration-info">
            Not ready to jump into the early bird offer just yet? No problem! Share your name and email with us, and we'll make sure you're among the first to know when the beta officially launches. While the exclusive perks are reserved for our early bird beta testers, you'll still be in the know and ready to join when the time is right!
          </p>
          <button onClick={toggleRegisterForm} className="cta-button">
            {isRegisterFormOpen ? 'Close Registration Form' : 'Register your interest for the Beta or Live Launch'}
          </button>

          {isRegisterFormOpen && (
            <form onSubmit={handleFormSubmit} className="registration-form">
              {!isSubmitted ? (
                <>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <button type="submit" className="cta-button">Submit</button>
                </>
              ) : (
                <p className="thank-you-message">Thank you for registering your interest! We will notify you as soon as the beta launch happens.</p>
              )}
            </form>
          )}
        </div>
      </motion.section>
          <Footer /> {/* Add this line */}

    </div>
  );
};

export default LandingPage;
