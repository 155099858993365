import React, { useState } from 'react';
import { BASE_URL } from './config'; // Import BASE_URL if needed
import LandingHeader from './LandingHeader'; // Ensure you have this component
import Footer from './Footer'; // Ensure you have this component

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
        setMessage('');
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${BASE_URL}/api/password-reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                setEmail('');
            } else {
                setError(data.error);
            }
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to send reset email. Please try again.');
        }
    };

    return (
        <div className="landing-page flex flex-col h-screen bg-gray-900 text-white">
            <LandingHeader />
            <div className="flex-grow flex justify-center items-start bg-transparent m-[40px]">
                <div className="bg-gray-800 p-8 rounded shadow-md w-full max-w-md">
                    <h2 className="text-2xl font-bold mb-6">Request Password Reset</h2>
                    {error && <p className="text-red-500">{error}</p>}
                    {message && <p className="text-green-500">{message}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-400 mb-2">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={handleChange}
                                className="w-full p-2 bg-gray-700 border border-gray-600 rounded text-white"
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
                            Send Reset Email
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PasswordResetRequest;