import React from 'react';
import './BiologyMemoryPage.css'; // Update if a new CSS file is created
import LandingHeader from './LandingHeader';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const BiologyMemoryPage = () => {
  return (
    <div className="landing-page">
      <LandingHeader />
      <section className="panel content-container">
        <h1 className="section-title">Master Biology with Memory Palaces!</h1>
        <p className="description">Unlock the power of your memory to ace Biology exams!</p>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=CfqVwAEB2Ns" // Replace with the new video ID
          className='react-player'
          playing
          controls
          width='100%'
          height='360px'
          style={{ minHeight: '350px', margin: '10px' }}
        />
        <Link to="/" className="find-out-more-btn">Explore More Learning Techniques</Link>
        <p>Video of teenager by Tima Miroshnichenko: <a href="https://www.pexels.com/video/frustrated-teenager-4873113/">https://www.pexels.com/video/frustrated-teenager-4873113</a></p>
      </section>
      <Footer />
    </div>
  );
};

export default BiologyMemoryPage;
