import React, { useState, useEffect } from 'react';
import TestStationCard from './TestStationCard';
import RandomTestStationCard from './RandomTestStationCard'; // Import the new component
import { Close } from '@mui/icons-material';
import './index.css';
import { BASE_URL } from './config';

const TestMemoryPalaceStationsPanel = ({ memoryPalaceId, memoryPalaceName, memoryPalaceDescription, memoryPalacePhoto, testMode, setTestMode }) => {
  const [stations, setStations] = useState([]);

  const fetchStations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/memory_palaces/${memoryPalaceId}/stations`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setStations(data);
    } catch (error) {
      console.error('Error fetching stations:', error);
    }
  };

  useEffect(() => {
    fetchStations();
  }, [memoryPalaceId]);

  const handleClose = () => {
    setTestMode(null);
  };

  return (
    <div className="memory-palace-stations-panel text-[#B7B7B7] bg-[#0a0a0a] text-white p-4 rounded-lg border-t border-r border-gray-400 h-full max-h-screen shadow-glow flex flex-col mb-2 overflow-hidden">
      <div className="flex justify-between items-center mb-4 border-b pb-5 pt-3">
        <h2 className="text-[#B7B7B7] text-1xl font-lustria">TEST MODE - MEMORY PALACE - {memoryPalaceName}</h2>
        <Close className="cursor-pointer" onClick={handleClose} />
      </div>
      <div className="mb-4">
        <p>{memoryPalaceDescription}</p>
        {memoryPalacePhoto && <img src={memoryPalacePhoto} alt="Memory Palace" className="w-auto h-64 rounded-lg border-4 border-gray-600" />}
      </div>
      <div className="pr-3 station-cards-container flex-grow overflow-auto panel-scroll">
        {testMode === 'random' ? (
          <RandomTestStationCard memoryPalaceId={memoryPalaceId} />
        ) : (
          Array.isArray(stations) && stations.map(station => (
            <TestStationCard
              key={station.id}
              station={station}
              testMode={testMode} // Pass the testMode prop
            />
          ))
        )}
      </div>
    </div>
  );
};

export default TestMemoryPalaceStationsPanel;
