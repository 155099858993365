// MainBody.js

import React, { useState } from 'react';
import LeftPanel from './LeftPanel';
import MainPanel from './MainPanel';
import { isEqual } from 'lodash';
import Header from './Header';

const MainBody = ({
  activeTab, setActiveTab, userId, isLoggedIn, setIsLoggedIn, isRightPanelVisible, stop, start,
  setUseBrowserTTS, addToQueue, cancelAll, isListening, useBrowserTTS, audioQueue, textQueue, isPlaying,
  audioContext, latestAudioBuffer, analyser, setSelectedModel, selectedModel, user, toggleRightPanel,setIsPlaying
}) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedMemoryPalaceId, setSelectedMemoryPalaceId] = useState(null);
  const [memoryPalaces, setMemoryPalaces] = useState([]);
  const [editingMemoryPalace, setEditingMemoryPalace] = useState(null); // New state for editing memory palace
  const [testMode, setTestMode] = useState(null); // New state for test mode
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState(chatHistory || []);
  const [assistantId, setAssistantId] = useState(null);

  const handleSelectChat = (history) => {
    setChatHistory(history);
  };

  const handleNodeSelection = (node) => {
    setSelectedNode(node);
  };
  return (
    <div id="main-body" className="main-container flex h-full mt-3 overflow-hidden pl-5 pr-5 pt-0 pb-5 main-body panel-scroll">

      <LeftPanel
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        userId={userId}
        onSelectChat={handleSelectChat}
        onSelectNode={handleNodeSelection}
        chatList={chatList}
        setChatList={setChatList}
        selectedChatId={selectedChatId}
        setSelectedChatId={setSelectedChatId}
        selectedMemoryPalaceId={selectedMemoryPalaceId}
        setSelectedMemoryPalaceId={setSelectedMemoryPalaceId}
        memoryPalaces={memoryPalaces}
        setMemoryPalaces={setMemoryPalaces}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        onEditMemoryPalace={setEditingMemoryPalace} // Pass the editing state handler
        setTestMode={setTestMode} // Pass the test mode handler
        inputText={inputText}
        setInputText={setInputText}
        messages={messages}
        setMessages={setMessages}
        assistantId={assistantId}
        setAssistantId={setAssistantId}
      />

      <MainPanel
        activeTab={activeTab}
        userId={userId}
        chatHistory={chatHistory}
        selectedNode={selectedNode}
        selectedMemoryPalaceId={selectedMemoryPalaceId}
        memoryPalaces={memoryPalaces}
        setChatList={setChatList}
        setSelectedChatId={setSelectedChatId}
        selectedChatId={selectedChatId}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isRightPanelVisible={isRightPanelVisible}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        editingMemoryPalace={editingMemoryPalace}
        setEditingMemoryPalace={setEditingMemoryPalace}
        stop={stop}
        start={start}
        setUseBrowserTTS={setUseBrowserTTS}
        addToQueue={addToQueue}
        cancelAll={cancelAll}
        isListening={isListening}
        useBrowserTTS={useBrowserTTS}
        audioQueue={audioQueue}
        textQueue={textQueue}
        isPlaying={isPlaying} // Add this line
        audioContext={audioContext} // Add this line
        latestAudioBuffer={latestAudioBuffer} // Add this line
        analyser={analyser}
        testMode={testMode} // Pass the testMode prop
        setTestMode={setTestMode} // Pass the test mode handler
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        user={user}  // Pass user object
        inputText={inputText}
        setInputText={setInputText}
        messages={messages}
        setMessages={setMessages}
        assistantId={assistantId}
        setAssistantId={setAssistantId}
      />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  //all must be true
  const activeTabEqual = isEqual(prevProps.activeTab, nextProps.activeTab);

  return activeTabEqual;
}

export default React.memo(MainBody, areEqual);
