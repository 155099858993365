import Cookies from "js-cookie";

export const fetchWithAuth = async (
  url,
  method,
  token,
  body = null,
  responseType = "json"
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const options = { method, headers };
  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error || "Request failed");
  }

  if (responseType === "blob") {
    return response.blob();
  }

  return response.json();
};

function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
function listAllCookies() {
  // Split the document.cookie string by semicolon to get individual key=value pairs
  const cookies = document.cookie.split("; ");
  const cookieList = {};
  // Iterate over each cookie pair
  cookies.forEach((cookie) => {
    // Split each key=value pair
    const [name, value] = cookie.split("=");
    // Decode cookie name and value, and store them in an object
    cookieList[decodeURIComponent(name)] = decodeURIComponent(value);
  });
  // Log or return the cookie list object
  console.log(cookieList);
  return cookieList;
}
// Example call to the function

const updateTokensFromCookies = () => {
  listAllCookies();
  const jwtToken = getCookie("supabase-auth-token");
  const refreshToken = getCookie("supabase-refresh-token");
  console.log("JWT Token:", jwtToken);
  console.log("Refresh Token:", refreshToken);
  if (jwtToken) {
    localStorage.setItem("token", jwtToken);
  }

  if (refreshToken) {
    localStorage.setItem("refresh-token", refreshToken);
  }
};

// Function to refresh the access token using the refresh token
const refreshToken = async (refreshToken) => {
  try {
    const response = await fetch(`${BASE_URL}/refresh-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refreshToken }),
    });
    if (response.ok) {
      console.log("We got a new token from calling refresh-token!");
      const data = await response.json();
      localStorage.setItem("token", data.token.accessToken);
      localStorage.setItem("refresh_token", data.token.refreshToken);
      return data.token.accessToken;
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null;
  }
};

// utils/api.js
export const apiFetch = async (url, options = {}) => {
  const defaultOptions = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const finalOptions = { ...defaultOptions, ...options };

  try {
    const response = await fetch(url, finalOptions);

    if (response.status === 401) {
      // Handle unauthorized access, e.g., redirect to login
      localStorage.removeItem("token");
      window.location.href = "/login";
      return;
    }

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const isTokenExpired = (token) => {
  const jwtPayload = JSON.parse(atob(token.split(".")[1]));
  const expiryDate = new Date(jwtPayload.exp * 1000);
  console.log("Token expiry date and time:", expiryDate);
  return Date.now() >= jwtPayload.exp * 1000;
};

// Initial token check and refresh logic
export const getValidAccessToken = async () => {
  return localStorage.getItem("token");
};

// Making a secure API request
export const fetchWithValidToken = async (url, options = {}) => {
  const token = await getValidAccessToken();
  if (!token) {
    // Handle unauthorized access, e.g., redirect to login
    localStorage.removeItem("token");
    window.location.href = "/login";
    return;
  }
  const accessToken = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refresh_token");
  const headers = {
    ...options.headers,
    "x-auth-token": accessToken,
    "x-refresh-token": refreshToken,
  };
  const response = await fetch(url, {
    ...options,
    headers,
    credentials: "include",
  });
  // Check for authorization errors
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.href = "/login";
    return;
  }

  // Extract tokens from response headers
  const newAccessToken = response.headers.get("X-Auth-Token");
  const newRefreshToken = response.headers.get("x-refresh-token");
  // Update local storage if new tokens are available
  if (newAccessToken) {
    localStorage.setItem("token", newAccessToken);
  }
  if (newRefreshToken) {
    localStorage.setItem("refresh_token", newRefreshToken);
  }
  return await response.json();
};
