import React, { useState } from "react";
import { BASE_URL } from "./config";
import { fetchWithValidToken } from "./utils";
const GenerateImagePanel = () => {
  const [model, setModel] = useState("dall-e-2");
  const [size, setSize] = useState("1024x1024");
  const [prompt, setPrompt] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleGenerateImage = async () => {
    setLoading(true);
    try {
      const response = await fetchWithValidToken(
        `${BASE_URL}/api/generate-image`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ model, prompt, size }),
        }
      );
      setImageUrl(response.imageUrl);
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="generate-image-panel text-[#B7B7B7] bg-black text-white rounded-lg max-w-full h-full p-5 shadow-glow overflow-auto">
      <h2 className="text-2xl mb-5">Generate Image</h2>
      <div className="form-group mb-4">
        <label htmlFor="model" className="block mb-2 text-lg font-medium">
          Model:
        </label>
        <select
          id="model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          className="p-2 bg-gray-800 text-white rounded w-full"
        >
          <option value="dall-e-2">Dall-E 2</option>
          <option value="dall-e-3">Dall-E 3</option>
        </select>
      </div>
      <div className="form-group mb-4">
        <label htmlFor="size" className="block mb-2 text-lg font-medium">
          Size:
        </label>
        <select
          id="size"
          value={size}
          onChange={(e) => setSize(e.target.value)}
          className="p-2 bg-gray-800 text-white rounded w-full"
        >
          <option value="1024x1024">1024x1024</option>
          <option value="1024x1792">1024x1792</option>
          <option value="1792x1024">1792x1024</option>
        </select>
      </div>
      <div className="form-group mb-4">
        <label htmlFor="prompt" className="block mb-2 text-lg font-medium">
          Prompt:
        </label>
        <input
          type="text"
          id="prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600"
        />
      </div>
      <button
        onClick={handleGenerateImage}
        disabled={loading}
        className={`p-2 rounded w-full ${
          loading
            ? "bg-gray-700 cursor-not-allowed"
            : "bg-blue-500 hover:bg-blue-700"
        } text-white`}
      >
        {loading ? "Generating..." : "Generate Image"}
      </button>
      {imageUrl && (
        <div className="image-result mt-5">
          <h3 className="text-xl mb-3">Generated Image:</h3>
          <div className="image-container relative w-full h-full overflow-auto">
            <img
              src={imageUrl}
              alt="Generated"
              className="object-contain max-w-full max-h-full"
            />
          </div>
          <div className="save-button mt-3">
            <a
              href={imageUrl}
              target="_blank"
              download="generated_image.png"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save Image
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default GenerateImagePanel;
