import React, { useState, useCallback, useRef } from 'react';
import ReactFlow, { Controls, Background, ReactFlowProvider } from 'reactflow';
import { shallow } from 'zustand/shallow';
import { useStore } from './hooks/useStore';
import 'reactflow/dist/style.css';
import './FlowDiagramPanel.css';
import CustomNode from './CustomNode';

const selector = (store) => ({
  nodes: store.nodes,
  edges: store.edges,
  onNodesChange: store.onNodesChange,
  onEdgesChange: store.onEdgesChange,
  addEdge: store.addEdge,
  addNode: store.addNode,
  updateNode: store.updateNode,
});

const nodeTypes = {
  custom: CustomNode,
};

const FlowDiagramPanel = () => {
  const store = useStore(selector, shallow);
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodeLabel, setNodeLabel] = useState('');
  const inputRef = useRef(null);
  const reactFlowWrapper = useRef(null);

  const handleAddNode = useCallback(() => {
    store.addNode({ label: 'New Node' });
  }, [store]);

  const handleNodeMouseDown = useCallback((event, node) => {
    setSelectedNode(node);
    setNodeLabel(node.data.label);
  }, []);

  const handleInputChange = useCallback((event) => {
    setNodeLabel(event.target.value);
  }, []);

  const handleInputBlur = useCallback(() => {
    if (selectedNode) {
      store.updateNode({ ...selectedNode, data: { label: nodeLabel } });
      setSelectedNode(null);
    }
  }, [selectedNode, nodeLabel, store]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      handleInputBlur();
    }
  }, [handleInputBlur]);

  return (
    <ReactFlowProvider>
      <div ref={reactFlowWrapper} className="flow-diagram-panel text-[#B7B7B7] bg-[#0a0a0a] text-white p-4 rounded-lg border-t border-r border-gray-400 h-full max-h-screen shadow-glow flex flex-col mb-2 overflow-hidden">
        <div className="controls mb-2">
          <button 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleAddNode}
          >
            Add Node
          </button>
        </div>
        <ReactFlow
          nodes={store.nodes.map(node => ({
            ...node,
            type: 'custom',
            data: {
              ...node.data,
              onMouseDown: handleNodeMouseDown
            }
          }))}
          edges={store.edges}
          onNodesChange={store.onNodesChange}
          onEdgesChange={store.onEdgesChange}
          onConnect={store.addEdge}
          nodeTypes={nodeTypes}
          style={{ width: '100%', height: '100%' }}
          fitView
        >
          <Controls />
          <Background />
        </ReactFlow>
        <div className="toolbar bg-gray-800 p-4 text-white w-full flex items-center space-x-4">
          <input
            ref={inputRef}
            value={nodeLabel}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            placeholder="Edit node label"
            className="bg-white text-black p-2 rounded shadow-md w-full"
          />
        </div>
      </div>
    </ReactFlowProvider>
  );
};

export default FlowDiagramPanel;
