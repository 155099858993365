import React, { useState } from "react";
import { BASE_URL } from "./config";
import LandingHeader from "./LandingHeader";
import Footer from "./Footer";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
        credentials: "include",
      });

      const data = await response.json();
      if (response.ok) {
        setError("");
        if (data.session && data.session.access_token) {
          onLogin(data.session.access_token, data.session.refresh_token);
        } else {
          setError("Failed to retrieve access token.");
        }
      } else {
        setError(data.error || "Login failed");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Failed to login. Please try again.");
    }
  };

  return (
    <div className="landing-page flex flex-col h-screen bg-gray-900 text-white">
      <LandingHeader />
      <div className="flex-grow flex justify-center items-start bg-transparent m-[40px]">
        <div className="bg-gray-800 p-8 rounded shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6">Login to Mind Raise</h2>
          {error && <p className="text-red-500">{error}</p>}
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 bg-gray-700 border border-gray-600 rounded text-white"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-400 mb-2">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 bg-gray-700 border border-gray-600 rounded text-white"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 rounded"
            >
              Login
            </button>
          </form>
          <p className="mt-4 text-gray-400">
            <a href="/password-reset-request" className="hover:underline">
              Forgot your password?
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
