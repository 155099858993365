import React, { useState, useEffect } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import './index.css';
import { BASE_URL } from './config';

const RandomTestStationCard = ({ memoryPalaceId }) => {
  const [questions, setQuestions] = useState([]);
  const [expandedQuestions, setExpandedQuestions] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${BASE_URL}/api/questions?memory_palace_id=${memoryPalaceId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          // Shuffle the questions randomly
          const shuffledQuestions = data.sort(() => 0.5 - Math.random());
          setQuestions(shuffledQuestions);
          const initialExpandedState = {};
          shuffledQuestions.forEach((_, index) => {
            initialExpandedState[index] = false; // Set to false to keep questions collapsed by default
          });
          setExpandedQuestions(initialExpandedState);
        } else {
          console.error('Unexpected data format:', data);
          setQuestions([]);
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
        setQuestions([]);
      }
    };

    fetchQuestions();
  }, [memoryPalaceId]);

  const toggleQuestionExpand = (questionId) => {
    setExpandedQuestions(prevState => ({
      ...prevState,
      [questionId]: !prevState[questionId]
    }));
  };

  return (
    <div>
      <h3 className="text-lg font-lustria" style={{ color: '#FFD700' }}>Random Questions</h3> {/* Golden color for the title */}
      {questions.length > 0 ? (
        questions.map((question, index) => (
          <div key={index} className="mb-2">
            <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleQuestionExpand(index)}>
              <p style={{ color: '#87CEEB' }}>{question.question}</p> {/* Sky blue color for the questions */}
              {expandedQuestions[index] ? <ExpandLess style={{ color: '#87CEEB' }} /> : <ExpandMore style={{ color: '#87CEEB' }} />}
            </div>
            {expandedQuestions[index] && (
              <p className="mt-2" style={{ color: '#B7B7B7' }}><strong>Answer:</strong> {question.answer}</p>
            )}
          </div>
        ))
      ) : (
        <p style={{ color: '#B7B7B7' }}>No questions available</p>
      )}
    </div>
  );
};

export default RandomTestStationCard;
