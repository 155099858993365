import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import { BASE_URL } from './config';
import { useSearchParams, Link } from 'react-router-dom';


const PasswordResetConfirm = () => {
    const hash = window.location.hash;
    let token = "";
    let refresh_token = "";
        if (hash) {
            const params = new URLSearchParams(hash.substring(1));
            token = params.get('access_token');
            refresh_token = params.get('refresh_token');
        }
    console.log(token);
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();

        const response = await fetch(`${BASE_URL}/api/password-reset-confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, refresh_token, password: newPassword }),
        });

        const data = await response.json();

        if (response.ok) {
            setMessage('Password has been reset successfully!');
        } else {
            setMessage(data.error);
        }
    };

    return (
        <div className="landing-page flex flex-col h-screen bg-gray-900 text-white">
            <LandingHeader />
            <div className="flex-grow flex justify-center items-start bg-transparent m-[40px]">
                <div className="bg-gray-800 p-8 rounded shadow-md w-full max-w-md">
                    <h2 className="text-2xl font-bold mb-6">Reset Your Password</h2>
                    {message && <p className="text-red-500">{message}</p>}
                    <form onSubmit={handleResetPassword}>
                        <div className="mb-4">
                            <label className="block text-gray-400 mb-2">New Password</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="w-full p-2 bg-gray-700 border border-gray-600 rounded text-white"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
                            Reset Password
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PasswordResetConfirm;