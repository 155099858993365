import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useAudioQueue from './useAudioQueue';

const useTextToSpeech = (openAiEndpoint) => {
  const [textQueue, setTextQueue] = useState([]);
  const [useBrowserTTS, setUseBrowserTTS] = useState(true);
  const [language, setLanguage] = useState('en-US');
  const [voiceModel, setVoiceModel] = useState(null);
  const { addToAudioQueue, clearAudioQueue, isPlaying, latestAudioBuffer, analyser: queueAnalyser, audioContext } = useAudioQueue();
  const [skipEffect, setSkipEffect] = useState(false); // Flag to control effect execution

  const browserTTSAnalyserRef = useRef(audioContext.createAnalyser());

  const prevTextQueueRef = useRef(null);

  useEffect(() => {
    if (skipEffect) return; // Skip effect if flag is set

    if (textQueue.length > 0 && !isPlaying) {
      if (useBrowserTTS) {
        if (JSON.stringify(textQueue[0]) !== JSON.stringify(prevTextQueueRef.current)) {
          playWithBrowserTTS(textQueue[0]);
          prevTextQueueRef.current = textQueue[0];
        }
      } else {
        if (JSON.stringify(textQueue[0]) !== JSON.stringify(prevTextQueueRef.current)) {
          fetchAndBufferAudio(textQueue[0]);
          prevTextQueueRef.current = textQueue[0];
        }
      }
    }
  }, [textQueue, isPlaying, useBrowserTTS]);

  const fetchAndBufferAudio = async (textObj) => {
    console.log("Fetching audio for text:", textObj);
    try {
      const response = await fetch(`${openAiEndpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: textObj.text }), // Use the text property of the textObj
      });

      if (!response.ok) {
        throw new Error('Failed to fetch audio from API');
      }

      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      addToAudioQueue(audioBuffer);
      setTextQueue(queue => queue.slice(1));

    } catch (error) {
      console.error("Error fetching audio from API:", error);
      handleSpeechEnd();
    }
  };

  const playWithBrowserTTS = (textObj) => {
    if (!('speechSynthesis' in window)) {
      console.error("Your browser does not support the Web Speech API");
      setSkipEffect(false); // Skip effect execution to prevent infinite loop
      return;
    }

    const utterance = new SpeechSynthesisUtterance();
    utterance.lang = language;
    utterance.text = textObj.text;
    utterance.volume = 1;

    if (voiceModel) {
      const voices = window.speechSynthesis.getVoices();
      const selectedVoice = voices.find((voice) => voice.name === voiceModel);
      if (!selectedVoice) {
        console.error(`The voice model "${voiceModel}" is not available`);
        return;
      }
      utterance.voice = selectedVoice;
    }

    const dummySource = audioContext.createOscillator();
    dummySource.connect(browserTTSAnalyserRef.current);
    browserTTSAnalyserRef.current.connect(audioContext.destination);

    utterance.onend = () => {
      handleSpeechEnd();
      dummySource.stop();
      dummySource.disconnect();
    };

    utterance.onerror = handleSpeechError;

    try {
      window.speechSynthesis.speak(utterance);

      // Create a silent dummy source to keep the audio context alive
      dummySource.frequency.setValueAtTime(0, audioContext.currentTime);
      dummySource.start();
    } catch (error) {
      console.error("Error during speech synthesis:", error);
      handleSpeechError(error);
    }
  };

  const handleSpeechEnd = () => {
    setTextQueue(queue => queue.slice(1));
  };

  const handleSpeechError = (error) => {
    console.error("Speech synthesis error", error.message);
    handleSpeechEnd();
  };

  const addToQueue = (text) => {
    setSkipEffect(false); // Skip effect execution to prevent infinite loop

    const sanitizedText = stripMarkdown(text);
    const chunks = chunkText(sanitizedText, 100);
    chunks.forEach(chunk => {
      const chunkWithId = { id: uuidv4(), text: chunk };
      setTextQueue((queue) => [...queue, chunkWithId]);
    });
  };

  const cancelAll = () => {
    if (useBrowserTTS) {
      window.speechSynthesis.cancel();
    } else {
      clearAudioQueue();
    }
    setTextQueue([]);
  };

  return {
    addToQueue,
    cancelAll,
    setUseBrowserTTS,
    setLanguage,
    setVoiceModel,
    isPlaying,
    textQueue,
    useBrowserTTS,
    latestAudioBuffer,
    analyser: useBrowserTTS ? browserTTSAnalyserRef.current : queueAnalyser,
  };
};

export default useTextToSpeech;

const stripMarkdown = (markdown) => {
  return markdown
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/\[(.*?)\]\(.*?\)/g, "$1")
    .replace(/!\[(.*?)\]\(.*?\)/g, "$1")
    .replace(/(\*\*|__)(.*?)\1/g, "$2")
    .replace(/(\*|_)(.*?)\1/g, "$2")
    .replace(/`(.*?)`/g, "$1")
    .replace(/^\s*>+\s?/gm, "")
    .replace(/^(#+)\s+/gm, "")
    .replace(/^(-\s*?|\*\s*?){3,}\s*$/gm, "")
    .replace(/~~(.*?)~~/g, "$1")
    .replace(/^\s*[-+*]\s+/gm, "")
    .replace(/^\s*\d+\.\s+/gm, "")
    .replace(/\n{2,}/g, "\n\n")
    .replace(/\n/g, " ")
    .trim();
};

const chunkText = (text, size) => {
  const words = text.split(' ');
  const chunks = [];
  let currentChunk = [];

  for (const word of words) {
    if ((currentChunk.join(' ') + ' ' + word).length <= size) {
      currentChunk.push(word);
    } else {
      chunks.push(currentChunk.join(' '));
      currentChunk = [word];
    }
  }

  if (currentChunk.length > 0) {
    chunks.push(currentChunk.join(' '));
  }

  return chunks;
};
