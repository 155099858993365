// MemoryPalaceSlideshowPanel.js
import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaUndo } from 'react-icons/fa'; // Import icons
import './index.css';

const MemoryPalaceSlideshowPanel = ({ stations, memoryPalaceName, onReturn }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % stations.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + stations.length) % stations.length);
  };

  const currentStation = stations[currentIndex];

  return (
<div className="memory-palace-slideshow-panel text-[#B7B7B7] bg-[#0a0a0a] text-white p-4 rounded-lg border-t border-r border-gray-400 h-full max-h-screen shadow-glow flex flex-col mb-2 overflow-hidden">
  <h2 className="text-[#B7B7B7] text-1xl font-lustria mb-4 border-b pb-5 pt-3">MEMORY PALACE - {memoryPalaceName} - SLIDESHOW</h2>
  <div className="flex-grow overflow-auto panel-scroll">
    <h3 className="text-xl font-semibold text-center mb-4 text-[#A0A0A0]">{currentStation.name} - Location {currentStation.position}</h3>
    <p className="text-center mb-4 text-[#A0A0A0]">{currentStation.description}</p>
    <div className="flex justify-center items-center">
      {currentStation.photo_url ? (
        <img src={currentStation.photo_url} alt="station" className="w-auto h-64 rounded-lg border-4 border-gray-600" />
      ) : (
        <p className="text-center">No Photo</p>
      )}
    </div>
    <div className="bg-gray-800 p-4 rounded-lg shadow-inner mb-4 mt-4">
      <p>Story: {currentStation.story}</p>
    </div>
    <div className="bg-gray-800 p-4 rounded-lg shadow-inner mb-4">
      <p className="font-">Fact: {currentStation.fact}</p>
    </div>
  </div>
  <div className="flex justify-between mt-4">
    <button onClick={handlePrev} className="py-2 px-4 bg-gray-700 rounded-md text-white hover:bg-gray-600">
      <FaArrowLeft />
    </button>
    <button onClick={onReturn} className="py-2 px-4 bg-gray-700 rounded-md text-white hover:bg-gray-600">
      <FaUndo />
    </button>
    <button onClick={handleNext} className="py-2 px-4 bg-gray-700 rounded-md text-white hover:bg-gray-600">
      <FaArrowRight />
    </button>
  </div>
</div>
  );
};

export default MemoryPalaceSlideshowPanel;
