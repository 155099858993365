import React, { memo } from 'react';
import { Handle } from 'reactflow';

const CustomNode = ({ id, data, selected }) => {
  return (
    <div
      onMouseDown={(event) => data.onMouseDown(event, { id, data })}
      style={{
        border: selected ? '2px solid #00f' : '1px solid #777',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: 'white',
        color: 'black',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <Handle type="target" position="top" />
      {data.label}
      <Handle type="source" position="bottom" />
    </div>
  );
};

export default memo(CustomNode);
