import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
import { FaFacebook, FaTiktok, FaSquareXTwitter } from 'react-icons/fa6';  // Importing the social media icons
import { FaEnvelope } from 'react-icons/fa'; // Importing the email icon

const Footer = () => {
  const navigate = useNavigate();

  const goToPrivacyPolicy = () => {
    navigate('/privacy-policy');
  };

  return (
    <footer className="footer-container mb-[80px]">
      <div className="social-links">
        <a href="https://x.com/MindRaiseIO" target="_blank" rel="noopener noreferrer"><FaSquareXTwitter className="icon" /></a>
        <a href="https://www.facebook.com/MindRaise.io/" target="_blank" rel="noopener noreferrer"><FaFacebook className="icon" /></a>
        <a href="https://www.tiktok.com/@mindraise" target="_blank" rel="noopener noreferrer"><FaTiktok className="icon" /></a>
        <a href="mailto:support@mindraise.io"><FaEnvelope className="icon" /></a>
      </div>
      <button onClick={goToPrivacyPolicy} className="privacy-policy-link">
        Privacy Policy
      </button>
    </footer>
  );
};

export default Footer;
