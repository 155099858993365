import React, { useEffect, useState } from 'react';
import { BASE_URL } from './config';
import { apiFetch } from './utils';


const AIMindPanel = ({
  setUseBrowserTTS, useBrowserTTS, setLanguage, setVoiceModel, setIsPlaying, setModel, selectedModel, setSelectedModel, userId, user
}) => {
  const [models, setModels] = useState([]);
  const [spendLogs, setSpendLogs] = useState([]);
  const [allData, setAllData] = useState(null); // New state to hold the data from the all-data endpoint

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const data = await apiFetch(`${BASE_URL}/api/ai_model_costs`);
        setModels(data);
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    const fetchSpendLogs = async () => {
      try {
        const data = await apiFetch(`${BASE_URL}/api/user_spends?userId=${user.id}`);
        setSpendLogs(data);
      } catch (error) {
        console.error('Error fetching spend logs:', error);
      }
    };

    const fetchAllData = async () => {
      try {
        const data = await apiFetch(`${BASE_URL}/api/weaviate/all-data`);
        setAllData(data);
      } catch (error) {
        console.error('Error fetching all data:', error);
      }
    };

    fetchModels();
    fetchSpendLogs();
    fetchAllData();
  }, [userId]);

  useEffect(() => {
    if (user?.selected_model_id) {
      const userSelectedModel = models.find(model => model.id === user.selected_model_id);
      setSelectedModel(userSelectedModel || { id: '1d86ba2c-156f-4bc9-a0b8-60b0787245b3', model_name: 'GPT-4o-mini' });
    } else {
      setSelectedModel({ id: '1d86ba2c-156f-4bc9-a0b8-60b0787245b3', model_name: 'GPT-4o-mini' });
    }
  }, [user, models, setSelectedModel]);

  const handleModelChange = (event) => {
    const userSelectedModel = models.find(model => model.id === event.target.value);
    setSelectedModel(userSelectedModel);
  };
// Step 1: Group models by type
const groupedModels = models.reduce((acc, model) => {
  const type = model.model_type; // Assuming each model has a model_type field
  if (!acc[type]) {
    acc[type] = [];
  }
  acc[type].push(model);
  return acc;
}, {});
  
  const truncateText = (text, length = 50) => {
    if (!text) return '';
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
  };
// Function to pad the model name to a fixed length
function padModelName(modelName, length) {
  const paddedLength = Math.max(modelName.length, length);
  return modelName.padEnd(paddedLength, ' ');
}

// Assuming a fixed length for model names for alignment
  const MODEL_NAME_LENGTH = 20;
  
  return (
    <div className="h-full overflow-auto w-full p-4">
      <h2 className="text-xl font-bold mb-4 text-gray-300">AI Model Settings</h2>
      <div className="mb-4">
        <label className="block text-gray-400">Select AI Model:</label>

<select onChange={handleModelChange} className="p-2 mt-2 bg-gray-800 text-white rounded w-full" value={selectedModel?.id || '1d86ba2c-156f-4bc9-a0b8-60b0787245b3'}>
  {Object.entries(groupedModels).map(([type, models]) => (
    <optgroup label={type} key={type}>
      {models.map((model) => (
        <option key={model.id} value={model.id}>
          {`${model.model_name} - ${
            model.model_type === 'STT' ? 
            `Voice: $${model.voice_cost_per_minute}/min` : 
            `Input: $${model.input_cost_per_1000_tokens}/1k tokens, Output: $${model.output_cost_per_1000_tokens}/1k tokens`
          }`}
        </option>
      ))}
    </optgroup>
  ))}
</select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-400">TTS/STT Options:</label>
        <div className="mt-2">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="ttsOption"
              value="browser"
              checked={useBrowserTTS}
              onChange={() => setUseBrowserTTS(true)}
              className="form-radio"
            />
            <span className="ml-2">Use Browser TTS/STT</span>
          </label>
        </div>
        <div className="mt-2">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="ttsOption"
              value="openai"
              checked={!useBrowserTTS}
              onChange={() => setUseBrowserTTS(false)}
              className="form-radio"
            />
            <span className="ml-2">Use OpenAI for TTS/STT</span>
          </label>
        </div>
      </div>
      <h2 className="text-xl font-bold mb-4 text-gray-300">Spend Logs</h2>
      <div className="overflow-auto max-h-64">
        <table className="w-full text-gray-300">
          <thead>
            <tr>
              <th className="border-b border-gray-600 p-2">Date</th>
              <th className="border-b border-gray-600 p-2">Model</th>
              <th className="border-b border-gray-600 p-2">Cost</th>
            </tr>
          </thead>
          <tbody>
            {spendLogs.map(log => (
              <tr key={log.id}>
                <td className="border-b border-gray-700 p-2">{log.date}</td>
                <td className="border-b border-gray-700 p-2">{log.model}</td>
                <td className="border-b border-gray-700 p-2">${log.cost}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h2 className="text-xl font-bold mb-4 text-gray-300">Weaviate Data</h2>
      {allData ? (
        Object.keys(allData).map(className => (
          <div key={className} className="mb-4">
            <h3 className="text-lg font-bold mb-2 text-gray-300">{className}</h3>
            <div className="overflow-auto max-h-64">
              <table className="w-full text-gray-300">
                <thead>
                  <tr>
                    {Object.keys(allData[className][0] || {}).map(key => (
                      <th key={key} className="border-b border-gray-600 p-2">{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allData[className].map((item, index) => (
                    <tr key={index}>
                      {Object.values(item).map((value, idx) => (
                        <td key={idx} className="border-b border-gray-700 p-2">
                          {typeof value === 'string' ? truncateText(value) : JSON.stringify(value)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-400">Loading data...</p>
      )}
    </div>
  );
};

export default AIMindPanel;
