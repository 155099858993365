// MessageList.js
import React, { useState, useRef, useEffect } from "react";
import Message from "./Message";
import { isEqual } from "lodash";

const MessageList = ({
  messages,
  copyToClipboard,
  messagesEndRef,
  maxWidth,
}) => {
  return (
    <div className="flex-grow overflow-auto bg-gray-900 p-3 rounded-lg mb-4 border border-gray-700 shadow-inner-custom flex flex-col w-full">
      {messages.map((msg, index) => (
        <Message
          key={index}
          msg={msg}
          copyToClipboard={copyToClipboard}
          maxWidth={maxWidth}
        />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  let isOK =
    isEqual(prevProps.messages, nextProps.messages) &&
    isEqual(prevProps.maxWidth, nextProps.maxWidth);
  return isOK;
}

export default React.memo(MessageList, areEqual);
