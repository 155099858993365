import React, { useEffect } from "react";
import styled from "styled-components";

// Styled component for the embedding area
const EmbedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${(props) => (props.theme === "light" ? "#ffffff" : "#1a1a1a")};
  color: ${(props) => (props.theme === "light" ? "#000000" : "#ffffff")};
  border: 1px solid ${(props) => (props.theme === "dark" ? "#ccc" : "#333")};
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

const FeatureBase = () => {
  useEffect(() => {
    // Load the script dynamically
    const script = document.createElement("script");
    script.src = "https://do.featurebase.app/js/sdk.js";
    script.id = "featurebase-sdk";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (typeof window.Featurebase !== "function") {
        window.Featurebase = function () {
          (window.Featurebase.q = window.Featurebase.q || []).push(arguments);
        };
      }
      window.Featurebase("embed", {
        organization: "mindraise", // Replace with your organization name
        basePath: null,
        theme: "dark",
        initialPage: "Board",
        hideMenu: false,
        hideLogo: false,
        filters: null,
        jwtToken: null,
        metadata: null,
        locale: "en",
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <EmbedWrapper theme="light">
          <div style={{width:'100%'}} data-featurebase-embed></div>
    </EmbedWrapper>
  );
};

export default FeatureBase;