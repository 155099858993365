// PaymentConfirmation.js
import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useSearchParams, Link } from 'react-router-dom';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import './PricingPage.css'; // Reuse the same CSS as PricingPage

const PaymentConfirmation = () => {
  const [message, setMessage] = useState(null);
  const [searchParams] = useSearchParams();

    useEffect(() => {


        const clientSecret = searchParams.get('result');
        const plan = searchParams.get('plan'); // Assuming the plan type is passed as a query parameter

   if (plan === 'free') {
        setMessage('You have successfully joined the Free Plan! You can now log in.');
    } else {
        switch (clientSecret) {
            case 'succeeded':
                setMessage('Payment succeeded! You can now log in.');
                break;
            case 'processing':
                setMessage('Your payment is processing.');
                break;
            case 'requires_payment_method':
                setMessage('Your payment was not successful, please try again.');
                break;
            default:
                setMessage('Something went wrong.');
                break;
        }
    }
    }, [searchParams]);


  return (
    <div className="landing-page">
      <LandingHeader />
      <section className="panel content-container">
<h1 className="section-title">
  {searchParams.get('plan') === 'free' ? 'Sign Up Successful!' : 'Payment Confirmation'}
</h1>        <div className="confirmation-message">
          <p>{message}</p>
          {message === 'Payment succeeded! You can now log in.' && (
            <Link to="/login" className="button-link">Go to Login</Link>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PaymentConfirmation;
