import { useState, useRef, useEffect } from 'react';

const useAudioQueue = () => {
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [latestAudioBuffer, setLatestAudioBuffer] = useState(null);
  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
  const audioBufferSourceRef = useRef(null);
  const analyserRef = useRef(audioContextRef.current.createAnalyser());

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      playNextAudioBuffer();
    }
  }, [audioQueue, isPlaying]);

  const playNextAudioBuffer = () => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    setIsPlaying(true);
    const nextAudioBuffer = audioQueue[0];
    setLatestAudioBuffer(nextAudioBuffer);
    audioBufferSourceRef.current = audioContextRef.current.createBufferSource();
    audioBufferSourceRef.current.buffer = nextAudioBuffer;
    audioBufferSourceRef.current.connect(analyserRef.current);
    analyserRef.current.connect(audioContextRef.current.destination);
    audioBufferSourceRef.current.onended = handleAudioEnd;
    audioBufferSourceRef.current.start(0);
  };

  const handleAudioEnd = () => {
    setAudioQueue(queue => queue.slice(1));
    setIsPlaying(false);
  };

  const addToAudioQueue = (audioBuffer) => {
    setAudioQueue(queue => [...queue, audioBuffer]);
  };

  const clearAudioQueue = () => {
    if (audioBufferSourceRef.current) {
      audioBufferSourceRef.current.stop();
    }
    setAudioQueue([]);
    setIsPlaying(false);
  };

  return {
    addToAudioQueue,
    clearAudioQueue,
    isPlaying,
    audioQueue,
    audioContext: audioContextRef.current,
    analyser: analyserRef.current,
    latestAudioBuffer,
  };
};

export default useAudioQueue;
