// LeftPanel.js

import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  Inbox,
  AccountTree,
  Nature,
  Memory,
  Android,
  Delete,
  Edit,
  Add,
  ArrowDropDown,
  Schema,
  Image,
} from "@mui/icons-material";
import GenericDialog from "./GenericDialog";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { BASE_URL } from "./config";
import { apiFetch, fetchWithValidToken } from "./utils";
import { isEqual } from "lodash";
import { useForceUpdate } from "framer-motion";

const LeftPanel = ({
  activeTab,
  setActiveTab,
  userId,
  onSelectChat,
  onSelectNode,
  chatList,
  setChatList,
  selectedChatId,
  setSelectedChatId,
  selectedMemoryPalaceId,
  memoryPalaces,
  setMemoryPalaces,
  setSelectedMemoryPalaceId,
  isLoggedIn,
  setIsLoggedIn,
  onEditMemoryPalace,
  setTestMode,
  inputText,
  setInputText,
  messages,
  setMessages,
  assistantId,
  setAssistantId,
}) => {
  const [knowledgeTree, setKnowledgeTree] = useState({ id: null, nodes: [] });
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [editingNodeId, setEditingNodeId] = useState(null);
  const [nodeNewName, setNodeNewName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [editingNode, setEditingNode] = useState(null);
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    title: "",
    description: "",
    label: "",
    value: "",
    onChange: () => {},
    onSubmit: () => {},
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedPalaceId, setSelectedPalaceId] = useState(null);

  const handleOpenDialog = (config) => {
    setDialogConfig({
      ...config,
      open: true,
      onChange: (event) =>
        setDialogConfig((prev) => ({ ...prev, value: event.target.value })),
      onSubmit: (value) => {
        config.onSubmit(value);
        handleCloseDialog();
      },
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setDialogConfig((prev) => ({ ...prev, open: false, value: "" }));
    setOpenDialog(false);
  };

  const handleSubmitDialog = (value) => {
    console.log("Submitted value:", value);
    handleCloseDialog();
  };

  useEffect(() => {
    if (activeTab === "ai") {
      fetchChatNames();
    } else if (activeTab === "knowledge") {
      fetchOrCreateKnowledgeTree();
    }
  }, [activeTab]);

  const fetchMemoryPalaces = async () => {
    const token = localStorage.getItem("token");
    try {
      const palaces = await fetchWithValidToken(
        `${BASE_URL}/api/memory_palaces`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMemoryPalaces(palaces);
      if (palaces.length > 0) {
        setSelectedMemoryPalaceId(palaces[0].id);
      }
    } catch (error) {
      console.error("Error fetching memory palaces:", error);
    }
  };

  useEffect(() => {
    if (activeTab === "memory") {
      fetchMemoryPalaces();
    }
  }, [activeTab]);

  const handleAddMemoryPalace = async (name) => {
    const token = localStorage.getItem("token");
    try {
      await fetchWithValidToken(`${BASE_URL}/api/memory_palaces`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name }),
      });

      fetchMemoryPalaces();
    } catch (error) {
      console.error("Error adding memory palace:", error);
    }
    handleCloseDialog();
  };

  const fetchChatNames = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(`${BASE_URL}/chats/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (selectedChatId === null) {
        setChatList(data);
        if (data.length > 0) {
          handleChatClick(data[data.length - 1].id);
        }
      } else {
        setChatList(data);
      }
    } catch (error) {
      console.error("Error fetching chat names:", error);
    }
  };

  const fetchOrCreateKnowledgeTree = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      const response = await fetchWithValidToken(`${BASE_URL}/api/trees`);

      const treeData = response;

      const nodesResponse = await fetchWithValidToken(
        `${BASE_URL}/api/trees/${treeData.id}/nodes`
      );
      const nodesData = await nodesResponse;

      // Filter out resource nodes and include only branch nodes
      const branchNodes = nodesData.filter(
        (node) => node.node_type === "branch"
      );
      setKnowledgeTree({ id: treeData.id, nodes: branchNodes });
    } catch (error) {
      console.error("Error fetching or creating knowledge tree:", error);
      setKnowledgeTree({ id: null, nodes: [] });
    }
  };

  const handleChatClick = async (chatId) => {
    setSelectedChatId(chatId);
    try {
      const response = await fetchWithValidToken(
        `${BASE_URL}/chats/${chatId}/messages`
      );
      onSelectChat(response);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchWithValidToken(
        `${BASE_URL}/chats/${chatId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        const updatedChatList = chatList.filter((chat) => chat.id !== chatId);

        // Check if the deleted chat was the selected chat
        if (chatId === selectedChatId) {
          setSelectedChatId(null); // Reset selected chat
          setMessages([]);
          setAssistantId(null);
          setInputText("");
        }

        setChatList(updatedChatList);
      } else {
        console.error("Error deleting chat:", await response.json());
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const handleEditChat = (chatId) => {
    const chat = chatList.find((chat) => chat.id === chatId);
    if (chat) {
      setDialogConfig({
        open: true,
        title: "Edit Chat",
        description: "Please enter the new name for the chat.",
        label: "Chat Name",
        value: chat.name,
        onChange: (e) =>
          setDialogConfig((prevConfig) => ({
            ...prevConfig,
            value: e.target.value,
          })),
        onSubmit: (newName) => {
          if (newName) {
            const updatedChats = chatList.map((chat) =>
              chat.id === chatId ? { ...chat, name: newName } : chat
            );
            setChatList(updatedChats);
          }
          setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
        },
      });
    }
  };

  const handleEditMemory = (memoryId) => {
    const memory = memoryPalaces.find((memory) => memory.id === memoryId);
    if (memory) {
      onEditMemoryPalace(memory); // Call the handler to set the editing memory palace
    }
  };

  const handleDeleteMemory = async (memoryId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found in localStorage");
    }

    try {
      const response = await fetchWithValidToken(
        `${BASE_URL}/api/memory_palaces/${memoryId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const updatedMemories = memoryPalaces.filter(
        (memory) => memory.id !== memoryId
      );
      setMemoryPalaces(updatedMemories);
    } catch (error) {
      console.error("Error deleting memory palace:", error.message);
    }
  };

  const handleAddTopLevelNode = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found in localStorage");
    }

    try {
      const response = await fetchWithValidToken(`${BASE_URL}/api/nodes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tree_id: knowledgeTree.id,
          parent_id: null,
          name: "Untitled",
          content: "",
          node_type: "branch",
        }),
      });

      if (response.ok) {
        const newNode = await response.json();
        setKnowledgeTree((prevState) => ({
          ...prevState,
          nodes: [...prevState.nodes, newNode],
        }));
      } else {
        console.error("Error adding node:", await response.json());
      }
    } catch (error) {
      console.error("Error adding node:", error);
    }
  };

  const handleNodeClick = async (node) => {
    setSelectedNodeId(node.id);
    try {
      const token = localStorage.getItem("token");
      const resources = await fetchWithValidToken(
        `${BASE_URL}/api/nodes/${node.id}/resources`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (onSelectNode) {
        onSelectNode({ ...node, resources });
      }
    } catch (error) {
      console.error("Error fetching node resources:", error);
    }
  };

  const handleNodeDoubleClick = (node) => {
    setEditingNodeId(node.id);
    setNodeNewName(node.name);
  };

  const handleNodeNameChange = (e) => {
    setNodeNewName(e.target.value);
  };
  const handleMemoryPalaceClick = (palace) => {
    setSelectedMemoryPalaceId(palace.id);
  };

  const handleNodeNameSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const updatedNode = await fetchWithValidToken(
        `${BASE_URL}/api/nodes/${editingNodeId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ name: nodeNewName }),
        }
      );

      setKnowledgeTree((prevState) => ({
        ...prevState,
        nodes: prevState.nodes.map((node) =>
          node.id === updatedNode.id ? updatedNode : node
        ),
      }));
      setEditingNodeId(null);
      setNodeNewName("");
    } catch (error) {
      console.error("Error updating node name:", error);
    }
  };

  const renderTreeNodes = (nodes, parentId = null) => {
    return (
      <ul
        style={{
          width: "100%",
          listStyleType: "none",
          paddingLeft: parentId ? "20px" : "0",
        }}
      >
        {Array.isArray(nodes) &&
          nodes
            .filter(
              (node) =>
                node.parent_id === parentId && node.node_type === "branch"
            )
            .map((node) => (
              <li
                key={node.id}
                className={`clickable chat-item ${node.id === selectedNodeId ? "selected" : ""}`}
                style={{
                  margin: "0",
                  flexDirection: "column",
                  paddingTop: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0px",
                }}
              >
                <div
                  onClick={() => handleNodeClick(node)}
                  onDoubleClick={() => handleNodeDoubleClick(node)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {editingNodeId === node.id ? (
                    <form
                      onSubmit={handleNodeNameSubmit}
                      className="inline"
                      style={{ flex: 1 }}
                    >
                      <input
                        type="text"
                        value={nodeNewName}
                        onChange={handleNodeNameChange}
                        onBlur={() => setEditingNodeId(null)}
                        autoFocus
                        className="inline p-1 rounded"
                        style={{ flex: 1 }}
                      />
                    </form>
                  ) : (
                    <>
                      <span
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {parentId && (
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ marginRight: "8px" }}
                          >
                            <path d="M9 3v12h12"></path>
                          </svg>
                        )}{" "}
                        {node.name}
                      </span>
                      <div>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddChildNode(node.id);
                          }}
                          style={{ color: "#fff" }}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteNode(node.id);
                          }}
                          style={{ color: "#fff" }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </div>
                    </>
                  )}
                </div>
                {renderTreeNodes(nodes, node.id)}
              </li>
            ))}
      </ul>
    );
  };

  const handleOpenMenu = (event, palaceId) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedPalaceId(palaceId);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setSelectedPalaceId(null);
  };

  const handleTestMemoryPalace = (memoryPalaceId, mode) => {
    setSelectedMemoryPalaceId(memoryPalaceId);
    setTestMode(mode);
    handleCloseMenu();
  };

  const handleDeleteNode = async (nodeId) => {
    const token = localStorage.getItem("token");

    // Check if node has children
    const hasChildren = knowledgeTree.nodes.some(
      (node) => node.parent_id === nodeId
    );
    if (hasChildren) {
      alert("Node has child nodes and cannot be deleted.");
      return;
    }

    try {
      await fetchWithValidToken(`${BASE_URL}/api/nodes/${nodeId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setKnowledgeTree((prevState) => ({
        ...prevState,
        nodes: prevState.nodes.filter((node) => node.id !== nodeId),
      }));
    } catch (error) {
      console.error("Error deleting node:", error);
    }
  };

  const handleAddChildNode = async (parentId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found in localStorage");
    }

    try {
      const newNode = await fetchWithValidToken(`${BASE_URL}/api/nodes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tree_id: knowledgeTree.id,
          parent_id: parentId,
          name: "Untitled",
          content: "",
          node_type: "branch",
        }),
      });

      setKnowledgeTree((prevState) => ({
        ...prevState,
        nodes: [...prevState.nodes, newNode],
      }));
    } catch (error) {
      console.error("Error adding node:", error);
    }
  };

  return (
    <div className="shadow-lg rounded-[25px] bg-black border-t border-gray-500 min-w-[400px] w-[400px] text-white left-panel">
      <div className="bg-black rounded-lg border-t border-r border-gray-400 shadow-glow m-4 h-[80px]">
        <div className="flex justify-around mt-[25px] text-gray-400 text-3xl">
          <Tooltip title="Inbox - COMING SOON">
            <Inbox
              className={`icon ${activeTab === "inbox" ? " text-[#B7B7B7]" : "inactive"}`}
            />
          </Tooltip>
          <Tooltip title="Projects - COMING SOON">
            <AccountTree
              className={`icon ${activeTab === "projects" ? " text-[#B7B7B7]" : "inactive"}`}
            />
          </Tooltip>
          <Tooltip title="Flow Diagrams - COMING SOON">
            <Schema
              className={`icon ${activeTab === "flow" ? " text-[#B7B7B7]" : "inactive"}`}
            />
          </Tooltip>
          <Tooltip title="Knowledge Tree">
            <Nature
              className={`icon ${activeTab === "knowledge" ? " text-[#B7B7B7]" : "inactive"}`}
              onClick={() => setActiveTab("knowledge")}
            />
          </Tooltip>
          <Tooltip title="Memory Palaces">
            <Memory
              className={`icon ${activeTab === "memory" ? " text-[#B7B7B7]" : "inactive"}`}
              onClick={() => setActiveTab("memory")}
            />
          </Tooltip>
          <Tooltip title="AI Chat History">
            <Android
              className={`icon ${activeTab === "ai" ? " text-[#B7B7B7]" : "inactive"}`}
              onClick={() => setActiveTab("ai")}
            />
          </Tooltip>
          <Tooltip title="Image Generation">
            <Image
              className={`icon ${activeTab === "image" ? " text-[#B7B7B7]" : "inactive"}`}
              onClick={() => setActiveTab("image")}
            />
          </Tooltip>
        </div>
      </div>
      <div className="flex-grow overflow-auto m-5 text-1xl font-lustria text-[#B7B7B7]">
        {activeTab === "inbox" && <div>INBOX</div>}
        {activeTab === "projects" && <div>PROJECTS</div>}
        {activeTab === "knowledge" && (
          <div>
            <div className="flex justify-between items-center">
              <div>KNOWLEDGE TREE</div>
              <Add onClick={() => handleAddTopLevelNode("branch")} />
            </div>
            <ul className="chat-list">
              {Array.isArray(knowledgeTree.nodes) &&
                renderTreeNodes(knowledgeTree.nodes)}
            </ul>
          </div>
        )}
        {activeTab === "memory" && (
          <div>
            <div className="flex justify-between items-center">
              <div>MEMORY PALACES</div>
              <Add
                onClick={() =>
                  handleOpenDialog({
                    title: "Add a Memory Palace",
                    description:
                      "Please enter the name of the new memory palace.",
                    label: "Memory Palace Name",
                    value: "",
                    onSubmit: handleAddMemoryPalace,
                  })
                }
              />
            </div>
            <ul className="chat-list">
              {Array.isArray(memoryPalaces) &&
                memoryPalaces.map((palace) => (
                  <li
                    key={palace.id}
                    className={`clickable chat-item ${selectedMemoryPalaceId === palace.id ? "selected" : ""}`}
                    onClick={() => handleMemoryPalaceClick(palace)}
                  >
                    <span>{palace.name}</span>
                    <div className="chat-icons">
                      <Edit
                        className="edit-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditMemory(palace.id);
                        }}
                      />
                      <Delete
                        className="delete-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMemory(palace.id);
                        }}
                      />
                      <ArrowDropDown
                        className="test-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenMenu(e, palace.id);
                        }}
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
        {activeTab === "ai" && (
          <div>
            <div>AI CHAT HISTORY</div>
            <ul className="chat-list">
              {Array.isArray(chatList) &&
                chatList.map((chat) => (
                  <li
                    key={chat.id}
                    className={`clickable chat-item ${selectedChatId === chat.id ? "selected" : ""}`}
                    onClick={() => handleChatClick(chat.id)}
                  >
                    <span>{chat.name}</span>
                    <div className="chat-icons">
                      <Edit
                        className="edit-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditChat(chat.id);
                        }}
                      />
                      <Delete
                        className="delete-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteChat(chat.id);
                        }}
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <div className="border-t border-gray-500 m-5 text-1xl font-lustria text-[#B7B7B7]">
        <div style={{ display: "none" }} className="mt-5">
          AREAS
        </div>
        <div style={{ display: "none" }}>RESOURCES</div>
      </div>
      <GenericDialog
        open={dialogConfig.open}
        onClose={handleCloseDialog}
        onSubmit={() => dialogConfig.onSubmit(dialogConfig.value)}
        title={dialogConfig.title}
        description={dialogConfig.description}
        label={dialogConfig.label}
        type="text"
        value={dialogConfig.value}
        onChange={dialogConfig.onChange}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          className: "bg-gray-800 text-white border border-black",
        }}
      >
        <MenuItem
          onClick={() => handleTestMemoryPalace(selectedPalaceId, "location")}
          className="hover:bg-gray-700 text-white"
        >
          Location Test
        </MenuItem>
        <MenuItem
          onClick={() => handleTestMemoryPalace(selectedPalaceId, "story")}
          className="hover:bg-gray-700 text-white"
        >
          Story Test
        </MenuItem>
        <MenuItem
          onClick={() => handleTestMemoryPalace(selectedPalaceId, "fact")}
          className="hover:bg-gray-700 text-white"
        >
          Fact Test
        </MenuItem>
        <MenuItem
          onClick={() => handleTestMemoryPalace(selectedPalaceId, "questions")}
          className="hover:bg-gray-700 text-white"
        >
          Questions Test
        </MenuItem>
        <MenuItem
          onClick={() => handleTestMemoryPalace(selectedPalaceId, "random")}
          className="hover:bg-gray-700 text-white"
        >
          Random Questions Test
        </MenuItem>
      </Menu>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  //all must be true
  const activeTabEqual = isEqual(prevProps.activeTab, nextProps.activeTab);
  const chatListEqual = isEqual(prevProps.chatList, nextProps.chatList);
  const memoryPalacesEqual = isEqual(
    prevProps.memoryPalaces,
    nextProps.memoryPalaces
  );
  const selectedMemoryPalaceIdEqual = isEqual(
    prevProps.selectedMemoryPalaceId,
    nextProps.selectedMemoryPalaceId
  );
  const selectedChatIdEqual = isEqual(
    prevProps.selectedChatId,
    nextProps.selectedChatId
  );
  return (
    activeTabEqual &&
    chatListEqual &&
    memoryPalacesEqual &&
    selectedMemoryPalaceIdEqual &&
    selectedChatIdEqual
  );
}

export default React.memo(LeftPanel, areEqual);
