import React, { useEffect, useRef, useState } from "react";

const useSpeechToText = () => {
    const [isListening, setIsListening] = useState(false);
    const [error, setError] = useState(null);
    const recognition = useRef(null);

    const manuallyStopped = useRef(false);


    useEffect(() => {
        if (!('webkitSpeechRecognition' in window)) {
            setError('Speech recognition is not supported in this browser. Please use Google Chrome.');
            return;
        }

        // Initialize the recognition object inside the useEffect
        recognition.current = new window.webkitSpeechRecognition();
        recognition.current.continuous = true;
        recognition.current.interimResults = false; // We only need final results
        recognition.current.lang = "en-US";
        
        const handleResult = (event) => {
            const transcript = Array.from(event.results)
                .map((result) => result[0])
                .map((result) => result.transcript)
                .join("");
            console.log("Transcript:", transcript); // Debugging line

            if (transcript.toLowerCase().includes("hey myra")) {
                // Dispatch a custom event when "Hey Mira" is heard
                const customEvent = new Event("heyMiraDetected");
              manuallyStopped.current = true;
              window.dispatchEvent(customEvent);
            }
        };

        const handleError = (event) => {
            console.error("Speech recognition error detected: " + event.error);
        };

        const handleStart = () => {
            setIsListening(true);
        };

        const handleEnd = () => {
            setIsListening(false);
            if (!manuallyStopped.current) {
                start(); // Restart recognition if it wasn't manually stopped
            }
        };

        recognition.current.addEventListener("result", handleResult);
        recognition.current.addEventListener("error", handleError);
        recognition.current.addEventListener("start", handleStart);
        recognition.current.addEventListener("end", handleEnd);

        return () => {
            recognition.current.removeEventListener("result", handleResult);
            recognition.current.removeEventListener("error", handleError);
            recognition.current.removeEventListener("start", handleStart);
            recognition.current.removeEventListener("end", handleEnd);
        };
    }, []);

    const start = () => {
            try {
                recognition.current.start();
                manuallyStopped.current = false;
                console.log("Speech recognition has started.");
                setIsListening(true);
            } catch (error) {
                console.error("Error starting speech recognition: ", error);
                setIsListening(false);
            }
    };

    const stop = () => {
            try {
                recognition.current.stop();
                console.log("Speech recognition has stopped.");
                setIsListening(false);
            } catch (error) {
                console.error("Error stopping speech recognition: ", error);
                setIsListening(false);
            }
        
    };

    return { start, stop, isListening, error };
};

export default useSpeechToText;
