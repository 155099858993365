// PricingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PricingPage.css';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
const plans = [
  {
    name: 'Free',
    price: '',
    benefits: [
      'Limited use of ChatGPT-4o-mini',
      'Limited size of knowledge tree',
      '3 Memory Palaces',
      '10 Locations in each Memory Palace',
      'Limited to 15mins of Audio Transcription per Day'
    ],
  },
  {
    name: 'Silver',
    price: '$5.99/month',
    benefits: [
      'Unlimited use of ChatGPT-4o-mini',
      'Limited use of ChatGPT-4o',
      '10 Memory Palaces',
      '20 Locations in each Memory Palace',
      'Limited to 30mins of Audio Transcription per Day'
    ],
  },
  {
    name: 'Gold',
    price: '$9.99/month',
    benefits: [
      'Unlimited use of ChatGPT-4o-mini',
      'Unlimited use of ChatGPT-4o',
      'Unlimited Memory Palaces',
      'Unlimited Locations in each Memory Palace',
      'Unlimited Number of Audio Transcription per Day',
      'Beta access to new features'
    ],
  },
];

const PricingPage = () => {
  const navigate = useNavigate();

  const handleChoosePlan = (plan) => {
    navigate(`/payment/${plan}`);
  };
  return (
    <div className="landing-page">
      <LandingHeader />
      <div className="panel content-container">
        <h1 className="section-title">Choose Your Plan</h1>
        <div style={{width:'100%', textAlign:'center'}} className="pricing-options">
          {plans.map((plan, index) => (
            <div style={{width:'100%', margin:'20px', textAlign:'center'}}  key={index} className="pricing-option">
              <h2>{plan.name} Plan</h2>
              <p className="price">{plan.price}</p>
              <ul>
                {plan.benefits.map((benefit, i) => (
                  <li key={i}><FontAwesomeIcon icon={faCheckCircle}/> {benefit}</li>
                ))}
              </ul>
              <button style={{ marginTop:'20px'}} onClick={() => handleChoosePlan(plan.name.toLowerCase())}>
                Choose Plan
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PricingPage;
