// Header.js
import React, { useEffect, useState, useRef } from "react";
import {
  FaUser,
  FaMicrophone,
  FaCoins,
  FaStop,
  FaBell,
  FaCheck,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "./config";
import { apiFetch, fetchWithValidToken } from "./utils";
import { io } from "socket.io-client";

import packageJson from "../package.json";

const Header = ({
  toggleRightPanel,
  isRightPanelVisible,
  stop,
  start,
  isListening,
  openAiEndpoint,
  addToQueue,
  cancelAll,
  setUseBrowserTTS,
  setIsPlaying,
  setIsLoggedIn,
  isPlaying,
  user,
}) => {
  const navigate = useNavigate();
  const notificationDropdownRef = useRef(null);
  const accountDropdownRef = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [accountDropdownVisible, setAccountDropdownVisible] = useState(false);
  const [notificationDropdownVisible, setNotificationDropdownVisible] =
    useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);

  const socket = useRef(null);

  const toggleListening = () => {
    if (isListening) {
      stop();
    } else {
      start();
    }
  };

  const markAsRead = async (id) => {
    try {
      // Call the API to mark the notification as read
      const response = await apiFetch(
        `${BASE_URL}/api/notifications/${id}/read`,
        { method: "POST" }
      );

      if (response.error) {
        throw new Error(response.error);
      }

      // Update the state to remove the notification from the list
      setNotifications((prevNotifications) => {
        const updatedNotifications = prevNotifications.filter(
          (n) => n.id !== id
        );
        console.log("Updated Notifications:", updatedNotifications); // Debugging line
        return updatedNotifications;
      });

      // Decrease the unread count
      setUnreadCount((prevCount) => prevCount - 1);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const data = await fetchWithValidToken(`${BASE_URL}/api/notifications`);
        setNotifications(data);
        setUnreadCount(
          data.filter((notification) => !notification.is_read).length
        );
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();

    // WebSocket setup
    socket.current = new WebSocket("wss://mindraise.io:8080");
    const token = localStorage.getItem("token");

    socket.current.onopen = () => {
      // Ensure the socket is open before sending the token
      if (socket.current.readyState === WebSocket.OPEN) {
        socket.current.send(JSON.stringify({ token }));
      }
    };

    socket.current.onmessage = (event) => {
      fetchMessages();
    };

    socket.current.onerror = (error) => {};

    socket.current.onclose = (event) => {
      if (event.wasClean) {
      } else {
        // Handle unexpected closures (like connection issues)
        // You might want to attempt a reconnection here
      }
    };

    return () => {
      if (socket.current) {
        // Ensure the socket is in a state where it can be safely closed
        if (
          socket.current.readyState === WebSocket.OPEN ||
          socket.current.readyState === WebSocket.CONNECTING
        ) {
          socket.current.close();
        }
      }
    };
  }, []);

  useEffect(() => {
    const fetchTokenBalance = async () => {
      try {
        const data = await fetchWithValidToken(`${BASE_URL}/api/token-balance`);
        setTokenBalance(data.tokenBalance);
      } catch (error) {
        console.error("Error fetching token balance:", error);
      }
    };

    fetchTokenBalance();
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const handleChangePassword = () => {
    setAccountDropdownVisible(false);
    navigate("/change-password");
  };

  const toggleVoiceModel = () => {
    setUseBrowserTTS((prev) => !prev);
  };

  const stopPlaying = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const handleHeyMiraDetected = () => {
      console.log("Hey Mira detected!");
      addToQueue(
        `Hey Peter, how can I help you today? I want you to tell me all about your issues. Do you know there are many ways that I can solve them?`
      );
      stop();
    };

    window.addEventListener("heyMiraDetected", handleHeyMiraDetected);

    return () => {
      window.removeEventListener("heyMiraDetected", handleHeyMiraDetected);
      if (isListening) {
        stop();
      }
    };
  }, [addToQueue, stop, isListening]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target)
      ) {
        setNotificationDropdownVisible(false);
      }
      if (
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target)
      ) {
        setAccountDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="h-[60px] rounded-[25px] w-full bg-black border-t border-[#8E8E8E] flex items-center header-panel">
      <h1 className="ml-[60px] text-1_5xl text-[#B7B7B7] font-lustria shadow-inner-custom text-spacing header-h1">
        MIND RAISE
      </h1>
      <div className="ml-auto mr-4 flex items-center mr-5">
        <FaCoins className="text-gray-300 text-xl" />
        <span className="ml-2 text-white">{tokenBalance}</span>
      </div>
      <button
        style={{ display: "none" }}
        onClick={toggleListening}
        className="mr-4 bg-gray-800 text-[#B7B7B7] p-2 rounded flex items-center"
      >
        <FaMicrophone className={isListening ? "text-red-500" : ""} />
      </button>
      <button
        style={{ display: "none" }}
        onClick={stopPlaying}
        className="mr-4 bg-gray-800 text-[#B7B7B7] p-2 rounded flex items-center"
      >
        <FaStop className={isPlaying ? "text-red-500" : ""} />
      </button>
      <div className="relative mr-[30px]" ref={notificationDropdownRef}>
        <FaBell
          style={{ fontSize: "1.3rem", color: "#B7B7B7" }}
          className="cursor-pointer"
          onClick={() => {
            setNotificationDropdownVisible(!notificationDropdownVisible);
            setAccountDropdownVisible(false);
          }}
        />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs px-2 transform translate-x-1/2 -translate-y-1/2">
            {unreadCount}
          </span>
        )}
        {notificationDropdownVisible && (
          <div className="absolute right-0 mt-2 w-80 bg-gray-800 rounded-md shadow-lg z-10">
            <div className="text-gray-400 text-sm p-4 border-b border-gray-700 flex justify-between">
              <span>Message</span>
              <span>Mark as Read</span>
            </div>
            {notifications.length === 0 ? (
              <div className="text-gray-400 text-sm p-4">
                No new notifications
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className="flex justify-between items-center p-4 border-b border-gray-700"
                >
                  <div>
                    <div className="text-gray-200 text-sm">
                      {notification.message}
                    </div>
                    <div className="text-gray-400 text-xs">
                      {new Date(notification.created_at).toLocaleString()}
                    </div>
                  </div>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      markAsRead(notification.id);
                    }}
                    className="text-green-500 text-sm ml-4"
                  >
                    <FaCheck />
                  </button>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <div
        className="relative mr-[20px] account-dropdown"
        ref={accountDropdownRef}
      >
        <FaUser
          className="text-[#B7B7B7] text-1xl cursor-pointer"
          onClick={() => {
            setAccountDropdownVisible(!accountDropdownVisible);
            setNotificationDropdownVisible(false);
          }}
        />
        {accountDropdownVisible && (
          <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10">
            <button
              onClick={handleChangePassword}
              className="block w-full text-left px-4 py-2 text-sm text-gray-400 hover:bg-gray-700"
            >
              Change Password
            </button>
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 text-sm text-gray-400 hover:bg-gray-700"
            >
              Logout
            </button>
          </div>
        )}
      </div>
      <span className="version text-gray-400 mr-[40px]">
        v{packageJson.version}
      </span>
    </div>
  );
};

export default Header;
