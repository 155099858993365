import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Header from "./Header";
import MainBody from "./MainBody";
import Login from "./Login";
import LandingPage from "./LandingPage";
import PioneersPage from "./PioneersPage";
import useSpeechToText from "./hooks/useSpeechToText";
import useTextToSpeech from "./hooks/useTextToSpeech";
import SignUp from "./SignUp";
import ChangePassword from "./ChangePassword";
import AIMindPanel from "./AIMindPanel";
import { BASE_URL } from "./config";
import PrivacyPolicy from "./PrivacyPolicy";
import CryptoMemoryPage from "./CryptoMemoryPage"; // Import at the top with other imports
import BiologyMemoryPage from "./BiologyMemoryPage"; // Import at the top with other imports
import PricingPage from "./PricingPage";
import PaymentPage from "./PaymentPage";
import PaymentConfirmation from "./PaymentConfirmation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PasswordResetConfirm from "./PasswordResetConfirm";
import PasswordResetRequest from "./PasswordResetRequest";
import { fetchWithValidToken } from "./utils";

const stripePromise = loadStripe(
  "pk_live_51PeAHRJgybmvZS1JJoC4ExTKhLmmsyEOjHXW9cBCAV39nlT15oshpEl04DsmjGh39HwyQbbV2zYkOqqgN8b756kW00hJyjlxf9"
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("ai");
  const [userId, setUserId] = useState(null);
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(true);
  const [selectedModel, setSelectedModel] = useState(null);
  const openAiEndpoint = `${BASE_URL}/api/tts`;
  const { start, stop, isListening, error } = useSpeechToText(openAiEndpoint);
  const tts = useTextToSpeech(openAiEndpoint);
  const [user, setUser] = useState(null);

  const {
    addToQueue,
    cancelAll,
    setUseBrowserTTS,
    setLanguage,
    setVoiceModel,
    isPlaying,
    textQueue,
    audioQueue,
    useBrowserTTS,
    setIsPlaying,
    audioContext,
    latestAudioBuffer,
    analyser,
  } = tts;

  const toggleRightPanel = () => {
    setIsRightPanelVisible(!isRightPanelVisible);
  };

  const location = useLocation();

  useEffect(() => {
    // Initialize GA4
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-MKQR00ELRN`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    window.gtag = gtag; // Make gtag function accessible globally
    gtag("js", new Date());
    gtag("config", "G-MKQR00ELRN");

    // Track initial page load
    gtag("event", "page_view", {
      page_path: window.location.pathname + window.location.search,
      page_title: document.title,
      screen_name: document.title,
    });
  }, []);

  useEffect(() => {
    // Update document title based on location
    const updateTitle = () => {
      let title;
      switch (location.pathname) {
        case "/dashboard":
          title = "Dashboard";
          break;
        case "/cryptomemory":
          title = "Crypto Memory";
          break;
        case "/biology-memory":
          title = "Biology Memory";
          break;
        case "/login":
          title = "Login";
          break;
        case "/pricing":
          title = "Pricing";
          break;
        case "/signup":
          title = "Sign Up";
          break;
        case "/change-password":
          title = "Change Password";
          break;
        case "/pioneers":
          title = "Pioneers";
          break;
        case "/privacy-policy":
          title = "Privacy Policy";
          break;
        case "/payment/free":
          title = "Free Plan";
          break;
        case "/payment/silver":
          title = "Silver Plan";
          break;
        case "/payment/gold":
          title = "Gold Plan";
          break;
        case "/payment-confirmation":
          title = "Payment Confirmation";
          break;
        default:
          title = "Mind Raise";
      }
      document.title = title;
    };

    // Track page views on route change
    if (window.gtag) {
      updateTitle();
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
        page_title: document.title,
        screen_name: document.title,
      });
    }
  }, [location]);

  useEffect(() => {
    const checkSession = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await fetch(`${BASE_URL}/session`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok && data && data.user && data.user.id) {
            setUserId(data.user.id);
            setUser(data.user);
            setIsLoggedIn(true);
          } else {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
          }
        } catch (error) {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
      setIsLoading(false);
    };
    checkSession();
  }, []);

  const handleLogin = async (token, refresh_token) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refresh_token);

    try {
      const response = await fetch(`${BASE_URL}/session`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok && data && data.user && data.user.id) {
        setUser(data.user);
        setUserId(data.user.id);
        setIsLoggedIn(true);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh-token");
        setIsLoggedIn(false);
      }
    } catch (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh-token");
      setIsLoggedIn(false);
    }
  };

  if (isLoading) {
    return (
      <div
        id="App"
        className="h-screen flex flex-col bg-cover bg-center p-5"
        style={{ backgroundImage: "url('./backdrop.jpg')" }}
      ></div>
    );
  }

  return (
    <div
      id="App"
      className="h-screen flex flex-col bg-cover bg-center panel-scroll"
      style={{ backgroundImage: "url('./backdrop.jpg')" }}
    >
      {isLoggedIn &&
        location.pathname !== "/" &&
        location.pathname == "/dashboard" && (
          <div className="pt-5 pl-5 pr-5 page-panel">
            <Header
              toggleRightPanel={toggleRightPanel}
              isRightPanelVisible={isRightPanelVisible}
              stop={stop}
              start={start}
              setUseBrowserTTS={setUseBrowserTTS}
              addToQueue={addToQueue}
              cancelAll={cancelAll}
              isListening={isListening}
              useBrowserTTS={useBrowserTTS}
              setIsPlaying={setIsPlaying}
              setIsLoggedIn={setIsLoggedIn}
              isPlaying={isPlaying}
              user={user}
            />
          </div>
        )}
      {error && isLoggedIn && !(<p className="text-red-500">{error}</p>)}
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to="/dashboard" />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/change-password"
            element={isLoggedIn ? <ChangePassword /> : <Navigate to="/login" />}
          />
          <Route path="/pioneers" element={<PioneersPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cryptomemory" element={<CryptoMemoryPage />} />
          <Route path="/crypto-memory" element={<CryptoMemoryPage />} />
          <Route path="/biology-memory" element={<BiologyMemoryPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/payment/:plan" element={<PaymentPage />} />
          <Route
            path="/payment-confirmation"
            element={<PaymentConfirmation />}
          />
          <Route path="/password-reset" element={<PasswordResetConfirm />} />
          <Route
            path="/password-reset-request"
            element={<PasswordResetRequest />}
          />

          <Route
            path="/dashboard"
            element={
              isLoggedIn ? (
                <MainBody
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  userId={userId}
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
                  isRightPanelVisible={isRightPanelVisible}
                  stop={stop}
                  start={start}
                  setUseBrowserTTS={setUseBrowserTTS}
                  addToQueue={addToQueue}
                  cancelAll={cancelAll}
                  isListening={isListening}
                  useBrowserTTS={useBrowserTTS}
                  audioQueue={audioQueue}
                  textQueue={textQueue}
                  isPlaying={isPlaying}
                  audioContext={audioContext}
                  latestAudioBuffer={latestAudioBuffer}
                  analyser={analyser}
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                  user={user}
                  toggleRightPanel={toggleRightPanel}
                  setIsPlaying={setIsPlaying}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Elements>
    </div>
  );
};

export default App;
