// MemoryPalaceStationEditPanel.js
import React, { useState } from "react";
import { BASE_URL } from "./config";
import AutoMode from "@mui/icons-material/AutoMode";
import { fetchWithValidToken } from "./utils";

const MemoryPalaceStationEditPanel = ({ station, onSave, onCancel }) => {
  const [name, setName] = useState(station.name || "");
  const [description, setDescription] = useState(station.description || "");
  const [story, setStory] = useState(station.story || "");
  const [fact, setFact] = useState(station.fact || "");
  const [photoUrl, setPhotoUrl] = useState(station.photo_url || "");
  const [position, setPosition] = useState(station.position || "");
  const [photoFile, setPhotoFile] = useState(null);

  const handleSave = async () => {
    const updatedStation = {
      ...station,
      name,
      description,
      story,
      fact,
      photo_url: photoUrl,
      position,
    };
    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/stations/${station.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedStation),
        }
      );

      onSave(updatedStation); // Pass the updated station back to the parent component
    } catch (error) {
      console.error("Error updating station:", error);
    }
  };

  const handlePhotoUpload = async () => {
    if (!photoFile) return;

    const formData = new FormData();
    formData.append("photo", photoFile);

    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/stations/${station.id}/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      setPhotoUrl(data); // Assuming the response contains the updated station with the new photo_url
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  const handleFileChange = (event) => {
    setPhotoFile(event.target.files[0]);
  };

  const handleGenerateStory = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(`${BASE_URL}/api/generate-story`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ description, fact }),
      });
      setStory(data.story);
    } catch (error) {
      console.error("Error generating story:", error);
    }
  };

  return (
    <div className="memory-palace-station-edit-panel text-[#B7B7B7] bg-[#0a0a0a] text-white p-4 rounded-lg border-t border-r border-gray-400 h-full max-h-screen shadow-glow flex flex-col mb-2 overflow-hidden">
      <h2 className="text-[#B7B7B7] text-1xl font-lustria">Edit Station</h2>
      <div className="flex-grow overflow-y-auto ai-panel pl-1 pr-1">
        <div className="flex flex-col space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Name
            </label>
            <input
              type="text"
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Description
            </label>
            <textarea
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Story
            </label>
            <textarea
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              value={story}
              onChange={(e) => setStory(e.target.value)}
            />
            <button
              onClick={handleGenerateStory}
              className="mt-2 py-2 px-4 bg-blue-600 rounded-md text-white flex items-center"
            >
              <AutoMode className="mr-2" /> Generate Story
            </button>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Fact
            </label>
            <textarea
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              value={fact}
              onChange={(e) => setFact(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Position
            </label>
            <input
              type="number"
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Photo
            </label>
            <div className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md">
              {photoUrl ? (
                <img
                  src={photoUrl}
                  alt="station"
                  style={{ width: "100px", height: "100px" }}
                />
              ) : (
                "No Photo"
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Upload Photo
            </label>
            <input
              type="file"
              className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded-md"
              onChange={handleFileChange}
            />
            <button
              onClick={handlePhotoUpload}
              className="mt-2 py-2 px-4 bg-blue-600 rounded-md text-white"
            >
              Upload Photo
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-4 mt-4">
        <button
          onClick={onCancel}
          className="py-2 px-4 bg-red-600 rounded-md text-white"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="py-2 px-4 bg-green-600 rounded-md text-white"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MemoryPalaceStationEditPanel;
