import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const YesNoDialog = ({ open, onClose, onConfirm, title, description }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="yes-no-dialog-title"
      aria-describedby="yes-no-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: '#000', // Black panel
          color: '#eee', // Grey text
          border: '1px solid #fff', // White border
          borderTopWidth: '1px',
          borderRightWidth: '1px',
          borderBottomWidth: '1px',
          borderLeftWidth: '1px',
          borderRadius: '8px', // Rounded corners
        },
      }}
    >
      <DialogTitle id="yes-no-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="yes-no-dialog-description"
          sx={{ color: '#ddd' }} // Ensure text is grey
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#fff' }} color="primary">
          No
        </Button>
        <Button onClick={onConfirm} sx={{ color: '#fff' }} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default YesNoDialog;
