import React, { useEffect, useState } from 'react';
import './PioneersPage.css';
import { BASE_URL } from './config';
import LandingHeader from './LandingHeader'; // Import the header component
import { motion } from 'framer-motion';

const PioneersPage = () => {
  const [pioneers, setPioneers] = useState([]);

  useEffect(() => {
    const fetchPioneers = async () => {
      const response = await fetch(`${BASE_URL}/api/pioneers`);
      const data = await response.json();
      setPioneers(data);
    };

    fetchPioneers();
  }, []);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="landing-page panel-scroll"> {/* Use the same class as LandingPage */}
      <LandingHeader /> {/* Include the header from the landing page */}
      <motion.section
        className="pioneers-section panel"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={sectionVariants}
        transition={{ duration: 0.5 }}
      >
        <div className="content-container"> {/* Wrap in a content container */}
          <h1 className="section-title">Pioneers</h1>
          <div className="pioneers-list">
            {pioneers.map((pioneer) => (
              <div key={pioneer.id} className="pioneer-card">
                {pioneer.photo_url && <img src={pioneer.photo_url} alt={pioneer.name} className="pioneer-photo" />}
                <h2 className="pioneer-name">{pioneer.name}</h2>
                {pioneer.link_url && (
                  <a href={pioneer.link_url} target="_blank" rel="noopener noreferrer" className="pioneer-link">
                    {pioneer.link_url}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default PioneersPage;
