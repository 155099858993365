import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import "./index.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

const handleUnhandledRejection = (event) => {
  console.error(
    "Unhandled promise rejection:",
    event.promise,
    "reason:",
    event.reason
  );
};

const handleGlobalError = (event) => {
  console.error(
    "Global error captured:",
    event.message,
    event.filename,
    event.lineno,
    event.colno,
    event.error
  );
};

window.addEventListener("unhandledrejection", handleUnhandledRejection);
window.addEventListener("error", handleGlobalError);

root.render(
  <Router>
    <App />
  </Router>
);
