import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function GenericDialog({ open, onClose, onSubmit, title, description, label, type = 'text', value, onChange }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={label}
          type={type}
          fullWidth
          variant="outlined"
          value={value}
                  onChange={onChange}
                    onKeyDown={(e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit(value);
    }
  }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSubmit(value)} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default GenericDialog;
