import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo-small.png';
import './LandingPage.css';

const LandingHeader = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const goToHome = () => {
    navigate('/');
  };

  const goToLogin = () => {
    navigate('/login');
  };

  const goToSignUp = () => {
    navigate('/pricing');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const goToPioneers = () => {
    navigate('/pioneers');
  };
  const goToPrivacyPolicy = () => {
  navigate('/privacy-policy');
};
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.landing-nav') && !event.target.closest('.hamburger')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className=" landing-header">
      <div className="title-container">
        <img src={logo} alt="Mind Raise Logo" className="logo" onClick={goToHome} style={{ cursor: 'pointer' }} />
        <div>
          <div className="title-home-container">
            <h1 className="title font-lustria" onClick={goToHome} style={{ cursor: 'pointer' }}>Mind Raise</h1>
            <a onClick={goToHome} className="home-link">Home</a>
          </div>
          <p className="tagline font-lustria">Unlock Your Full Potential with AI-Powered Productivity</p>
        </div>
      </div>
      <nav className={`landing-nav ${isMenuOpen ? 'open' : ''}`}>
        <button onClick={goToSignUp} className="nav-link">Sign Up</button>
        {/*<button onClick={goToPioneers} className="nav-link">Pioneers Page</button>*/}
        <button onClick={goToLogin} className="nav-link">Log In</button>

      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default LandingHeader;
