import React, { useState, useEffect } from "react";
import { BASE_URL } from "./config";
import { fetchWithValidToken } from "./utils";

const LatestNewsPanel = () => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(`${BASE_URL}/api/latest-news`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setArticles(data.results);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  return (
    <div className="h-full overflow-auto">
      <ul className="newslist flex-grow">
        {Array.isArray(articles) &&
          articles.map((article) => (
            <li
              key={article.link}
              className="news-item mb-4 p-2 border-b border-gray-600"
            >
              <h3 className="font-lustria news-title text-1xl text-gray-200 mb-2">
                {article.title}
              </h3>
              <p className="news-description text-gray-300 text-sm font-lustria">
                {article.description}
              </p>
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                className="news-link text-blue-200"
              >
                Read more
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default React.memo(LatestNewsPanel);
