import React from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom'; // Added useNavigate import
import { BASE_URL } from './config';

const CheckoutForm = ({ plan, name, email, password, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Added navigate function

 const handleSubmit = async (event) => {
  event.preventDefault();
  if (!stripe || !elements) return;

  const { error, paymentIntent } = await stripe.confirmPayment({
    elements,
    confirmParams: {
      return_url: `${window.location.origin}/payment-confirmation`,
      payment_method_data: {
        billing_details: { email },
      },
    },
    redirect: 'if_required',
  });

  if (error) {
    console.error(error);
    return;
  }

  if (paymentIntent && paymentIntent.status === 'succeeded') {
    // Call your registration endpoint with the paymentIntent ID
    const response = await fetch(`${BASE_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        password,
        paymentIntentId: paymentIntent.id,
        plan: plan.toLowerCase(),
      }),
    });

    const data = await response.json();
    if (data.error) {
      console.error('Registration error:', data.error);
    } else {
      console.log('Registration successful:', data);
        navigate(`/payment-confirmation?result=${data.result}`); // Redirect with clientSecret
    }
  }
};

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button type="submit" disabled={!stripe}>
        Pay for {plan} Plan
      </button>
    </form>
  );
};

export default CheckoutForm;
