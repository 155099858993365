import React, { useState, useEffect } from "react";
import { Add, Slideshow } from "@mui/icons-material";
import GenericDialog from "./GenericDialog";
import StationCard from "./StationCard";
import "./index.css";
import { BASE_URL } from "./config";
import { fetchWithValidToken } from "./utils";

const MemoryPalaceStationsPanel = ({
  memoryPalaceId,
  memoryPalaceName,
  memoryPalaceDescription,
  memoryPalacePhoto,
  onShowSlideshow,
  stations,
  setStations,
  setEditStation,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    title: "",
    description: "",
    label: "",
    value: "",
    onChange: () => {},
    onSubmit: () => {},
  });

  // Fetch stations function
  const fetchStations = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/memory_palaces/${memoryPalaceId}/stations`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setStations(data);
    } catch (error) {
      console.error("Error fetching stations:", error);
    }
  };

  // Handle adding station
  const handleAddStation = async (name) => {
    try {
      const token = localStorage.getItem("token");
      const newStation = await fetchWithValidToken(`${BASE_URL}/api/stations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ memory_palace_id: memoryPalaceId, name }),
      });

      setEditStation(newStation[0]);
      fetchStations();
    } catch (error) {
      console.error("Error adding station:", error);
    }
    handleCloseDialog();
  };

  // Handle adding question
  const handleAddQuestion = async (stationId) => {
    handleOpenDialog({
      title: "Add a Question/Answer",
      description: "Please enter the question and answer.",
      label: "Question/Answer",
      value: { question: "", answer: "" },
      onChange: (event) =>
        setDialogConfig((prev) => ({
          ...prev,
          value: { ...prev.value, [event.target.name]: event.target.value },
        })),
      onSubmit: async (value) => {
        try {
          const token = localStorage.getItem("token");
          const response = await fetchWithValidToken(
            `${BASE_URL}/api/questions`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                station_id: stationId,
                question: value.question,
                answer: value.answer,
              }),
            }
          );
          fetchStations();
        } catch (error) {
          console.error("Error adding question:", error);
        }
      },
    });
  };

  // Handle deleting question
  const handleDeleteQuestion = async (questionId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchWithValidToken(
        `${BASE_URL}/api/questions/${questionId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchStations();
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  // Dialog open and close handlers
  const handleOpenDialog = (config) => {
    setDialogConfig({
      ...config,
      open: true,
      onChange: (event) =>
        setDialogConfig((prev) => ({ ...prev, value: event.target.value })),
      onSubmit: (value) => {
        config.onSubmit(value);
        handleCloseDialog();
      },
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setDialogConfig((prev) => ({ ...prev, open: false, value: "" }));
    setOpenDialog(false);
  };

  const handleEditStation = (station) => {
    setEditStation(station);
  };

  const handleDeleteStation = async (stationId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found in localStorage");
    }

    try {
      const response = await fetchWithValidToken(
        `${BASE_URL}/api/stations/${stationId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStations(stations.filter((station) => station.id !== stationId));
    } catch (error) {
      console.error("Error deleting station:", error.message);
    }
  };

  useEffect(() => {
    fetchStations();
  }, [memoryPalaceId]);

  return (
    <div className="memory-palace-stations-panel text-[#B7B7B7] bg-[#0a0a0a] text-white p-4 rounded-lg border-t border-r border-gray-400 h-full max-h-screen shadow-glow flex flex-col mb-2 overflow-hidden">
      <div className="flex justify-between items-center mb-4 border-b pb-5 pt-3">
        <h2 className="text-[#B7B7B7] text-1xl font-lustria">
          MEMORY PALACE - {memoryPalaceName}
        </h2>
        <div className="flex items-center">
          <button
            onClick={() =>
              handleOpenDialog({
                title: "Add a Station",
                description: "Please enter the name of the new station.",
                label: "Station Name",
                value: "",
                onSubmit: handleAddStation,
              })
            }
            className="icon new-station-icon"
          >
            <Add style={{ color: "#ffffff" }} />
          </button>
          <button
            onClick={onShowSlideshow}
            className="icon slideshow-icon ml-2"
          >
            <Slideshow style={{ color: "#ffffff" }} />
          </button>
        </div>
      </div>
      <div className="mb-4">
        <p>{memoryPalaceDescription}</p>
        {memoryPalacePhoto && (
          <img
            src={memoryPalacePhoto}
            alt="Memory Palace"
            className="w-auto h-64 rounded-lg border-4 border-gray-600"
          />
        )}
      </div>
      <div className="pr-3 station-cards-container flex-grow overflow-auto panel-scroll">
        {Array.isArray(stations) &&
          stations.map((station) => (
            <StationCard
              key={station.id}
              station={station}
              onEdit={handleEditStation}
              onDelete={handleDeleteStation}
              onAddQuestion={handleAddQuestion}
              onDeleteQuestion={handleDeleteQuestion}
            />
          ))}
      </div>
      <GenericDialog
        open={dialogConfig.open}
        onClose={handleCloseDialog}
        onSubmit={() => dialogConfig.onSubmit(dialogConfig.value)}
        title={dialogConfig.title}
        description={dialogConfig.description}
        label={dialogConfig.label}
        type="text"
        value={dialogConfig.value}
        onChange={dialogConfig.onChange}
      />
    </div>
  );
};

export default MemoryPalaceStationsPanel;
