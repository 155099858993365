import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
          color: '#ffffff', // Text color
              fontSize: '16px', // Increase font size

      iconColor: '#ffffff', // Icon color
      backgroundColor: '#32325d', // Background color of the input
      '::placeholder': {
        color: '#aab7c4', // Placeholder color
      },
    },
    invalid: {
      color: '#fa755a', // Text color for invalid input
      iconColor: '#fa755a', // Icon color for invalid input
    },
  },
};

const CardInput = () => {
  return (
    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
      <label style={{ color: '#aab7c4', marginBottom: '10px', display: 'block', textAlign: 'left' }}>
        Credit Card Details
      </label>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
};

export default CardInput;