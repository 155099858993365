import React, { useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { Highlight, themes } from "prism-react-renderer";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import ErrorBoundary from "./ErrorBoundary";

const Message = ({ msg, copyToClipboard, maxWidth }) => {
  const [copyStatus, setCopyStatus] = useState("Copy Code");

  const copyCodeToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopyStatus("Copied!");
        setTimeout(() => setCopyStatus("Copy Code"), 2000);
      })
      .catch((err) => console.error("Error copying text:", err));
  };
  function cleanNewlines(content) {
    // Replace double newlines with single newlines
    let cleanedContent = content.replace(/\n\n/g, "\n");
    return cleanedContent;
  }

  return (
    <div
      className={`mb-4 text-gray-100 p-2 rounded-lg relative ${msg.sender === "user" ? "bg-blue-900 p-3 text-left self-end max-w-[70%]" : "bg-gray-800 text-left self-start max-w-[100%] p-4 pt-8"} inline-block`}
      style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", maxWidth }}
    >
      {msg.sender === "assistant" && (
        <FaRegCopy
          className="copy-icon"
          onClick={() => copyToClipboard(msg.content)}
        />
      )}
      {msg.sender === "assistant" ? (
        <ErrorBoundary>
          <ReactMarkdown
            className="markdown"
            remarkPlugins={[remarkGfm, remarkBreaks]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                const code = String(children).replace(/\n$/, "");
                return !inline && match ? (
                  <div
                    style={{
                      position: "relative",
                      overflowX: "visible",
                      paddingTop: "2rem",
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <button
                      onClick={() => copyCodeToClipboard(code)}
                      style={{
                        position: "absolute",
                        top: "0.5rem",
                        right: "0rem",
                        backgroundColor: "#333",
                        color: "#fff",
                        border: "none",
                        padding: "5px",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    >
                      {copyStatus}
                    </button>

                    <Highlight
                      theme={themes.nightOwl}
                      code={code}
                      language={match[1]}
                    >
                      {({
                        className,
                        style,
                        tokens,
                        getLineProps,
                        getTokenProps,
                      }) => (
                        <pre
                          className={`${className} syntax-highlighter w-full max-w-full overflow-x-auto`}
                          style={{
                            ...style,
                            padding: "1em",
                            overflowX: "auto",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            maxWidth,
                          }}
                        >
                          {tokens.map((line, i) => {
                            const lineProps = getLineProps({ line });
                            return (
                              <div
                                key={i}
                                {...lineProps}
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {line.map((token, tokenKey) => {
                                  const tokenProps = getTokenProps({ token });
                                  return (
                                    <span key={tokenKey} {...tokenProps} />
                                  );
                                })}
                              </div>
                            );
                          })}
                        </pre>
                      )}
                    </Highlight>
                  </div>
                ) : (
                  <code
                    className={className}
                    style={{
                      maxWidth,
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                    {...props}
                  >
                    {children}
                  </code>
                );
              },
            }}
          >
            {cleanNewlines(msg.content)}
          </ReactMarkdown>
        </ErrorBoundary>
      ) : (
        msg.content.split("\n").map((line, index) => (
          <div
            style={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}
            key={index}
          >
            {line}
            <br />
          </div>
        ))
      )}
    </div>
  );
};

export default React.memo(Message);
