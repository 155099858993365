import { fetchWithAuth, isTokenExpired, getValidAccessToken } from "./utils";
import { BASE_URL } from "./config";
import { fetchWithValidToken } from "./utils";

// Function to handle TTS requests
const fetchTTS = async (text) => {
  const token = localStorage.getItem("token");
  const response = await fetchWithAuth(
    `${BASE_URL}/api/tts`,
    "POST",
    token,
    { text },
    "blob"
  );
  const audioBlob = new Blob([response], { type: "audio/mpeg" });
  return URL.createObjectURL(audioBlob);
};

const formatCodeInMarkdown = (text) => {
  return text.replace(/```(\w+)([\s\S]*?)```/g, (match, lang, code) => {
    return `<pre><code class="language-${lang}">${code.trim()}</code></pre>`;
  });
};

export const handleSend = async ({
  userId,
  inputText,
  setInputText,
  setMessages,
  setIsInputDisabled,
  setAssistantId,
  setChatList,
  selectedChatId,
  setSelectedChatId,
  messages,
  chatId,
  verbosity,
  isTTSEnabled,
  messagesEndRef,
}) => {
  console.log("handleSend setSelectedChatId:", setSelectedChatId); // Add this log
  try {
    if (!userId) throw new Error("User ID is missing");
    if (getValidAccessToken() == null) {
      window.location.href = "/login";
      return;
    }

    const userMessage = inputText;
    setInputText("");
    setMessages((prev) => [
      ...prev,
      { sender: "user", content: userMessage, chat_id: chatId || null },
    ]);
    setIsInputDisabled(true);

    const token = localStorage.getItem("token");
    const response = await fetchWithAuth(
      `${BASE_URL}/chats/messages`,
      "POST",
      token,
      {
        initialMessage: userMessage,
        chatId,
        verbosity,
      }
    );

    setChatId(response.chatId);
    setMessages((prev) => [...prev, ...response.assistantMessages]);
    setIsInputDisabled(false);

    if (response.newChat) {
      const updatedChatList = await fetchWithAuth(
        `${BASE_URL}/chats/${userId}`,
        "GET",
        token
      );
      console.log("Updated chat list:", updatedChatList);
      setChatList(updatedChatList);
      console.log("Before setting setSelectedChatId:", setSelectedChatId); // Add this log
      setSelectedChatId(response.chatId);
      console.log("After setting setSelectedChatId:", setSelectedChatId); // Add this log
    }

    if (isTTSEnabled) {
      // Handle Text-to-Speech
      const assistantMessages = response.assistantMessages || [];
      for (const message of assistantMessages) {
        if (message.sender === "assistant" && message.content) {
          const audioUrl = await fetchTTS(message.content);
          const audio = new Audio(audioUrl);
          audio.play();
        }
      }
    }
  } catch (error) {
    console.error("Error:", error);
    setMessages((prev) => [
      ...prev,
      { sender: "error", content: `Error: ${error.message}` },
    ]);
    setIsInputDisabled(false);
  }
};

export const handleStreamSend = ({
  userId,
  inputText,
  uploadedFiles,
  setInputText,
  setMessages,
  setIsInputDisabled,
  setAssistantId,
  setChatList,
  selectedChatId,
  setSelectedChatId,
  messages,
  verbosity,
  isTTSEnabled,
  messagesEndRef,
  addToQueue,
  ttsBuffer,
  cancelAll,
  selectedModel,
  setIsUploading,
  bigBrain,
}) => {
  try {
    if (!userId) throw new Error("User ID is missing");
    if (getValidAccessToken() == null) {
      window.location.href = "/login";
      return;
    }

    const userMessage = inputText;
    setInputText("");
    setMessages((prev) => [
      ...prev,
      { sender: "user", content: userMessage, chat_id: selectedChatId || null },
    ]);
    setIsInputDisabled(true);

    const token = localStorage.getItem("token");
    const streamMessageId = new Date().getTime(); // Unique ID for the streaming message
    setMessages((prev) => [
      ...prev,
      {
        id: streamMessageId,
        sender: "assistant",
        content: "",
        chat_id: selectedChatId,
      },
    ]);

    let formData = {};
    formData.userId = userId;
    formData.model = selectedModel.model_name;
    formData.chatId = selectedChatId;
    formData.message = inputText;
    formData.files = uploadedFiles;
    formData.verbosity = verbosity;
    formData.bigBrain = bigBrain;

    // Fetch the short-lived token for the EventSource
    fetchWithValidToken(`${BASE_URL}/generate-token`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((data) => {
        const shortLivedToken = data.token;

        // Make an API call to prepare and store chat data
        fetchWithValidToken(`${BASE_URL}/api/prepare-chat`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
          .then((data) => {
            if (data.success) {
              // Start the EventSource with the session ID returned from the server
              const eventSource = new EventSource(
                `${BASE_URL}/api/stream?sessionId=${data.chatSessionId}&token=${shortLivedToken}`
              );

              eventSource.onmessage = async (event) => {
                setIsUploading(false);
                const parsedData = JSON.parse(event.data);
                if (parsedData.type === "textDelta") {
                  setMessages((prev) =>
                    prev.map((msg) =>
                      msg.id === streamMessageId
                        ? { ...msg, content: parsedData.snapshot.value }
                        : msg
                    )
                  );
                  if (isTTSEnabled) {
                    ttsBuffer.current += parsedData.textDelta.value;
                    if (ttsBuffer.current.length >= 100) {
                      // Find the nearest space before the 100th character
                      let boundaryIndex = ttsBuffer.current.lastIndexOf(
                        " ",
                        100
                      );
                      // If no space is found, default to the 100th character
                      if (boundaryIndex === -1) {
                        boundaryIndex = 100;
                      }
                      const chunk = ttsBuffer.current.slice(0, boundaryIndex);
                      ttsBuffer.current = ttsBuffer.current
                        .slice(boundaryIndex)
                        .trim();
                      addToQueue(chunk);
                    }
                  }
                } else if (
                  parsedData.type === "textCreated" &&
                  !selectedChatId
                ) {
                  setIsUploading(false);
                  const chatId = parsedData.chatId; // Update local variable
                  const updatedChatList = await fetchWithValidToken(
                    `${BASE_URL}/chats/${userId}`
                  );
                  setChatList(updatedChatList);
                  setSelectedChatId(chatId);
                  // Optionally update the message with the new chatId
                  setMessages((prev) =>
                    prev.map((msg) =>
                      msg.id === streamMessageId
                        ? { ...msg, chat_id: parsedData.chatId }
                        : msg
                    )
                  );
                }

                if (isTTSEnabled && parsedData.sender === "assistant") {
                  const audioUrl = await fetchTTS(parsedData.snapshot.value);
                  const audio = new Audio(audioUrl);
                  audio.play();
                }
              };

              eventSource.onerror = (error) => {
                console.error("EventSource failed: ", error);
                eventSource.close();
                setIsInputDisabled(false);
              };

              eventSource.addEventListener("end", () => {
                setIsInputDisabled(false);
                if (ttsBuffer.current.length > 0) {
                  addToQueue(ttsBuffer.current);
                  ttsBuffer.current = "";
                }
                eventSource.close();
                messagesEndRef.current?.scrollIntoView({
                  behavior: "auto",
                  block: "nearest",
                  inline: "start",
                });
              });
            } else {
              throw new Error("Failed to prepare chat session");
            }
          })
          .catch((error) => {
            console.error("Error initializing chat:", error);
            setMessages((prev) => [
              ...prev,
              { sender: "error", content: `Error: ${error.message}` },
            ]);
            setIsInputDisabled(false);
          });
      })
      .catch((error) => {
        console.error("Error fetching short-lived token:", error);
        setMessages((prev) => [
          ...prev,
          { sender: "error", content: `Error: ${error.message}` },
        ]);
        setIsInputDisabled(false);
      });

    setInputText("");
    setIsInputDisabled(true);
  } catch (error) {
    console.error("Error:", error);
    setMessages((prev) => [
      ...prev,
      { sender: "error", content: `Error: ${error.message}` },
    ]);
    setIsInputDisabled(false);
  }
};
