import React from 'react';
import './CryptoMemoryPage.css';
import LandingHeader from './LandingHeader';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom'; // Import Link for client-side routing
import Footer from './Footer'; // Import Footer component
const CryptoMemoryPage = () => {
  return (
    <div className="landing-page">
      <LandingHeader />
      <section className="panel content-container">
        <h1 className="section-title">Never Leave Your Wallet Lying Around!</h1>
        <p className="description">Memorization = Greater Anonymity & Greater Security</p>
<ReactPlayer
  url="https://www.youtube.com/watch?v=fWoSMtg4JPY"
  className='react-player'
  playing
  controls
  width='100%'
  height='360px' // Set a fixed height or minimum height
  style={{ minHeight: '350px', margin: '10px' }} // Ensuring minHeight is applied through inline styles
/>
        <Link to="/" className="find-out-more-btn">Learn More About Mind Raise</Link> {/* Button to navigate to the home page */}
          </section>
                    <Footer /> {/* Add this line */}
    </div>
  );
};

export default CryptoMemoryPage;
