import React, { useState, useEffect } from "react";
import { fetchWithValidToken } from "./utils";
import defaultImage from "./assets/logo.png";
import { BASE_URL } from "./config";

const UniversityPanel = () => {
  const [resources, setResources] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/learning-resources`
      );
      setResources(data.resources);
    };
    fetchData();
  }, []);
  return (
    <div className="university-panel text-[#B7B7B7] bg-black text-white h-full pr-1 max-h-screen w-full flex flex-col mb-2 overflow-hidden">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-[#B7B7B7] ml-2 text-1xl font-lustria">
          University Learning Resources
        </h2>
      </div>
      <div className="flex-grow overflow-auto bg-gray-900 p-3 rounded-lg mb-4 border border-gray-700 shadow-inner-custom flex flex-col">
        {resources.length === 0 ? (
          <p>No resources found.</p>
        ) : (
          <ul>
            {resources.map((resource) => (
              <li
                key={resource.id}
                className="flex items-center bg-gray-800 text-white p-4 rounded-lg mb-4 break-words cursor-pointer"
              >
                <img
                  src={resource.image || defaultImage}
                  alt={resource.title}
                  className="w-16 h-16 object-cover rounded mr-4"
                />
                <div className="flex flex-col">
                  <h3 className="text-lg mb-2">{resource.title}</h3>
                  <p>{resource.description}</p>
                  <a
                    href={resource.link}
                    className="mt-2 text-blue-400 underline"
                  >
                    Learn more
                  </a>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default UniversityPanel;
