import React, { useState, useEffect, useRef, useCallback } from "react";
import MessageList from "./MessageList";
import { handleSend, handleStreamSend } from "./chatService";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ChatIcon from "@mui/icons-material/Chat";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";
import { BASE_URL } from "./config";
import { fetchWithValidToken } from "./utils";
import { isEqual } from "lodash";
import TextareaAutosize from "react-textarea-autosize";

const AIPanel = ({
  userId,
  chatHistory,
  setChatList,
  selectedChatId,
  setSelectedChatId,
  isExpanded,
  openAiEndpoint,
  stop,
  start,
  setUseBrowserTTS,
  addToQueue,
  cancelAll,
  isListening,
  useBrowserTTS,
  user,
  inputText,
  setInputText,
  messages,
  setMessages,
  assistantId,
  setAssistantId,
  maxWidth,
  setMaxWidth,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]); // List of uploaded files
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [verbosity, setVerbosity] = useState(5);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isTTSEnabled, setIsTTSEnabled] = useState(false);
  const [isStreamingEnabled, setIsStreamingEnabled] = useState(true);
  const ttsBuffer = useRef("");
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [intermediateInput, setIntermediateInput] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [bigBrain, setBigBrain] = useState(false);
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const data = await fetchWithValidToken(
          `${BASE_URL}/api/ai_model_costs`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const chatModels = data.filter((model) => model.model_type === "CHAT");
        setModels(chatModels);

        if (chatModels.length > 0) {
          const userSelectedModel = chatModels.find(
            (model) => model.id === user.selected_model_id
          );
          if (userSelectedModel) {
            setSelectedModel(userSelectedModel); // Set the user's selected model
          } else {
            setSelectedModel(chatModels[0]); // Otherwise, set the first chat model as the default
          }
        }
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, [userId]);

  useEffect(() => {
    const ttsInterval = setInterval(() => {
      if (ttsBuffer.current) {
        addToQueue(ttsBuffer.current);
        ttsBuffer.current = "";
      }
    }, 1000);

    return () => clearInterval(ttsInterval);
  }, [addToQueue]);

  useEffect(() => {
    setMessages(chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleModelAPICall = async (model) => {
    try {
      const data = await fetchWithValidToken(
        `${BASE_URL}/api/users/${userId}/update-model`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ model }),
        }
      );
      // Handle response data if needed
    } catch (error) {
      console.error("Error in model update API call:", error);
    }
  };

  const handleModelChange = (event) => {
    const userSelectedModel = models.find(
      (model) => model.id === event.target.value
    );
    setSelectedModel(userSelectedModel);
    handleModelAPICall(userSelectedModel); // Make the API call on model selection
  };

  const handleInputChange = useCallback((e) => {
    setInputText(e.target.value);
  }, []);

  const handleSendMessage = () => {
    const sendFunction = isStreamingEnabled ? handleStreamSend : handleSend;

    let messageContent = inputText;
    setIsUploading(true);

    sendFunction({
      userId,
      inputText: messageContent,
      setInputText,
      setMessages,
      setIsInputDisabled,
      setAssistantId,
      setChatList,
      selectedChatId,
      setSelectedChatId,
      messages,
      verbosity,
      isTTSEnabled,
      messagesEndRef,
      addToQueue,
      ttsBuffer,
      cancelAll,
      selectedModel,
      uploadedFiles,
      setIsUploading,
      bigBrain,
    });

    setUploadedFiles([]);
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey && !isInputDisabled) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [isInputDisabled, handleSendMessage]
  );

  const handleNewChat = () => {
    setSelectedChatId(null);
    setMessages([]);
    setAssistantId(null);
    setInputText("");
  };

  const allowedFileTypes = [
    "text/x-c",
    "text/x-csharp",
    "text/x-c++",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/html",
    "text/x-java",
    "application/json",
    "text/markdown",
    "application/pdf",
    "text/x-php",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/x-python",
    "text/x-script.python",
    "text/x-ruby",
    "text/x-tex",
    "text/plain",
    "text/css",
    "text/javascript",
    "application/x-sh",
    "application/typescript",
  ];

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        alert("Invalid file type. Please select a valid file.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      setIsUploading(true);

      try {
        const result = await fetchWithValidToken(`${BASE_URL}/api/upload`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        });

        setUploadedFiles((prev) => [
          ...prev,
          { name: file.name, link: result.fileUrl },
        ]);
        setMessages((prev) => [
          ...prev,
          {
            sender: "user",
            content: `File uploaded: ${file.name}`,
            link: result.fileUrl,
          },
        ]);
      } catch (error) {
        console.error("File upload error:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const copyToClipboard = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => alert("Copied to clipboard"))
      .catch((err) => console.error("Error copying text:", err));
  };

  const toggleTTS = () => {
    setIsTTSEnabled((prev) => !prev);
  };

  const toggleStreaming = () => {
    setIsStreamingEnabled((prev) => !prev);
  };

  return (
    <div
      className={`ai-panel text-[#B7B7B7] bg-black text-white rounded-lg h-full max-h-screen w-full shadow-glow flex flex-col mb-2 overflow-hidden max-w-full`}
    >
      <div className="flex justify-between items-center mb-2 rounded-lg border-t border-r border-gray-400 shadow-glow p-4 mb-5">
        <h2 className="text-[#B7B7B7] ml-2 text-1xl font-lustria">
          VIRTUAL ASSISTANT
        </h2>
        <div className="flex items-center">
          <button
            className="icon new-chat-icon"
            onClick={handleNewChat}
            style={{ background: "none", border: "none" }}
          >
            <ChatIcon style={{ color: "#ffffff" }} />
          </button>
        </div>
      </div>

      <MessageList
        messages={messages}
        copyToClipboard={copyToClipboard}
        messagesEndRef={messagesEndRef}
        maxWidth={maxWidth}
      />
      <div className="control-panel w-full pt-2">
        <div className="mb-4 ml-[3px]">
          <label className="text-lg font-medium text-gray-700 mr-2">
            Select Model:
          </label>
          <select
            onChange={handleModelChange}
            className="p-2 bg-gray-800 text-white rounded w-full"
            value={selectedModel?.id || ""}
          >
            {models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.model_name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center mb-4">
          <label
            htmlFor="bigbrain"
            className="text-lg font-medium text-gray-700 mr-2"
          >
            Enable Big Brain:
          </label>
          <div className="flex mr-4">
            <input
              type="checkbox"
              id="bigbrain"
              name="bigbrain"
              checked={bigBrain}
              onChange={(e) => setBigBrain(!bigBrain)}
            ></input>
          </div>
          <label
            htmlFor="verbosity"
            className="text-lg font-medium text-gray-700 mr-2"
          >
            Response Detail ({verbosity}):
          </label>
          <div className="flex-grow">
            <input
              type="range"
              id="verbosity"
              name="verbosity"
              min="1"
              max="10"
              value={verbosity}
              onChange={(e) => setVerbosity(e.target.value)}
              className="w-full cursor-pointer"
            />
          </div>
        </div>

        <div className="flex items-start mt-2 mb-2">
          <button
            onClick={() => fileInputRef.current.click()}
            className="p-2 bg-gray-700 text-white rounded"
          >
            <AttachFileIcon />
          </button>
          <TextareaAutosize
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600 ml-2"
            placeholder="Ask a question."
            disabled={isInputDisabled}
            minRows={1}
            maxRows={10}
            style={{
              lineHeight: "1.5",
              resize: "none",
              whiteSpace: "pre-wrap",
            }}
          />
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {isUploading && (
          <div className="flex justify-center items-center mt-2">
            <p>Processing</p>
            <CircularProgress color="secondary" />
          </div>
        )}
        {isSpeaking && <p>Speaking.</p>}
      </div>
    </div>
  );
};

export default AIPanel;
