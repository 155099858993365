import { applyNodeChanges, applyEdgeChanges } from 'reactflow';
import { nanoid } from 'nanoid';
import { create } from 'zustand';

export const useStore = create((set, get) => ({
  nodes: [
    { id: '1', type: 'default', data: { label: 'Start Node' }, position: { x: 250, y: 5 }, style: { border: '1px solid #777', padding: '10px', borderRadius: '5px' } },
    { id: '2', type: 'default', data: { label: 'Process Node' }, position: { x: 100, y: 100 }, style: { border: '1px solid #777', padding: '10px', borderRadius: '5px' } },
    { id: '3', type: 'default', data: { label: 'End Node' }, position: { x: 400, y: 100 }, style: { border: '1px solid #777', padding: '10px', borderRadius: '5px' } },
  ],
  edges: [
    { id: 'e1-2', source: '1', target: '2', type: 'smoothstep' },
    { id: 'e1-3', source: '1', target: '3', type: 'smoothstep' },
  ],

  onNodesChange(changes) {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },

  onEdgesChange(changes) {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },

  addEdge(data) {
    const id = nanoid(6);
    const edge = { id, ...data };

    set({ edges: [edge, ...get().edges] });
  },

  addNode(data) {
    const id = nanoid(6);
    const node = {
      id,
      data: { label: data.label },
      position: { x: Math.random() * 400, y: Math.random() * 400 },
      style: { border: '1px solid #777', padding: '10px', borderRadius: '5px' },
    };
    set({ nodes: [node, ...get().nodes] });
  },

  updateNode(node) {
    const nodes = get().nodes.map(n => (n.id === node.id ? node : n));
    set({ nodes });
  },
}));
