import React, { useState, useEffect, useRef } from "react";
import { Edit, Delete, AddCircleOutline, AutoMode } from "@mui/icons-material";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "./index.css";
import { BASE_URL } from "./config";
import { fetchWithValidToken } from "./utils";

const StationCard = ({ station, onEdit, onDelete }) => {
  const [questions, setQuestions] = useState([]);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingAnswerId, setEditingAnswerId] = useState(null);
  const [newQuestionText, setNewQuestionText] = useState("");
  const [newAnswerText, setNewAnswerText] = useState("");
  const [story, setStory] = useState(station.story); // Add state for story
  const questionInputRef = useRef(null);
  const answerInputRef = useRef(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem("token");
        const data = await fetchWithValidToken(
          `${BASE_URL}/api/questions?station_id=${station.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (Array.isArray(data)) {
          setQuestions(data);
        } else {
          console.error("Unexpected data format:", data);
          setQuestions([]);
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        setQuestions([]);
      }
    };

    fetchQuestions();
  }, [station.id]);

  const handleAddQuestion = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/api/questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          station_id: station.id,
          memory_palace_id: station.memory_palace_id,
          question: "Enter your question here",
          answer: "Enter your answer here",
        }),
      });
      if (response.ok) {
        const newQuestion = await response.json();
        setQuestions([...questions, newQuestion[0]]);
      } else {
        console.error("Failed to add question:", await response.json());
      }
    } catch (error) {
      console.error("Error adding question:", error);
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/api/questions/${questionId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setQuestions(
          questions.filter((question) => question.id !== questionId)
        );
      } else {
        console.error("Failed to delete question:", await response.json());
      }
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleUpdateQuestion = async (questionId, newQuestion) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/api/questions/${questionId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ question: newQuestion }),
      });
      if (response.ok) {
        setQuestions(
          questions.map((q) =>
            q.id === questionId ? { ...q, question: newQuestion } : q
          )
        );
      } else {
        console.error("Failed to update question:", await response.json());
      }
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  const handleUpdateAnswer = async (questionId, newAnswer) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/api/questions/${questionId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ answer: newAnswer }),
      });
      if (response.ok) {
        setQuestions(
          questions.map((q) =>
            q.id === questionId ? { ...q, answer: newAnswer } : q
          )
        );
      } else {
        console.error("Failed to update answer:", await response.json());
      }
    } catch (error) {
      console.error("Error updating answer:", error);
    }
  };

  const handleKeyDown = (e, questionId, type) => {
    if (e.key === "Enter") {
      if (type === "question") {
        handleUpdateQuestion(questionId, newQuestionText);
        setEditingQuestionId(null);
      } else if (type === "answer") {
        handleUpdateAnswer(questionId, newAnswerText);
        setEditingAnswerId(null);
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleGenerateStory = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/generate-story`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          description: station.description,
          fact: station.fact,
        }),
      });
      const data = await response.json();
      // Update the story state with the new story
      setStory(data.story);
    } catch (error) {
      console.error("Error generating story:", error);
    }
  };

  return (
    <div className="station-card bg-[#0f0f0f] p-4 rounded-lg shadow-md mb-4 shadow-inner-custom border-t border-r border-gray-400 shadow-glow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-lustria text-[#B7B7B7]">{`${station.position}. ${station.name}`}</h3>
        <div className="flex space-x-2">
          <Edit
            className="edit-icon text-[#B7B7B7]"
            onClick={() => onEdit(station)}
          />
          <Delete
            className="delete-icon text-[#B7B7B7]"
            onClick={() => onDelete(station.id)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="w-1/4">
          {station.photo_url ? (
            <Zoom>
              <img
                src={station.photo_url}
                alt="station"
                className="custom-zoom-image rounded-md"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Zoom>
          ) : (
            <p className="text-[#B7B7B7]">No Photo</p>
          )}
        </div>
        <div className="w-3/4 pl-4 text-sm">
          <p className="text-[#B7B7B7]">
            <strong>Description:</strong> {station.description}
          </p>
          <p className="text-[#B7B7B7]">
            <strong>Story:</strong> {story}
          </p>
          <p className="text-[#B7B7B7]">
            <strong>Fact:</strong> {station.fact}
          </p>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <h4 className="text-lg font-lustria text-[#B7B7B7]">Questions</h4>
          <AddCircleOutline
            className="text-[#B7B7B7] cursor-pointer"
            onClick={handleAddQuestion}
          />
        </div>
        <ul className="mt-2">
          {questions.map((question) => (
            <li
              key={question.id}
              className="flex justify-between items-center mt-2"
            >
              <div className="flex-grow">
                {editingQuestionId === question.id ? (
                  <input
                    ref={questionInputRef}
                    type="text"
                    defaultValue={question.question}
                    onChange={(e) => setNewQuestionText(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, question.id, "question")}
                    onBlur={() => setEditingQuestionId(null)}
                    onFocus={handleFocus}
                    className="bg-transparent border-b border-gray-600 focus:outline-none text-[#B7B7B7] w-full"
                    autoFocus
                  />
                ) : (
                  <p
                    className="text-[#B7B7B7] cursor-pointer"
                    onDoubleClick={() => {
                      setEditingQuestionId(question.id);
                      setNewQuestionText(question.question);
                      setTimeout(
                        () =>
                          questionInputRef.current &&
                          questionInputRef.current.focus(),
                        0
                      );
                    }}
                  >
                    <strong>Q:</strong> {question.question}
                  </p>
                )}
                {editingAnswerId === question.id ? (
                  <input
                    ref={answerInputRef}
                    type="text"
                    defaultValue={question.answer}
                    onChange={(e) => setNewAnswerText(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, question.id, "answer")}
                    onBlur={() => setEditingAnswerId(null)}
                    onFocus={handleFocus}
                    className="bg-transparent border-b border-gray-600 focus:outline-none text-[#B7B7B7] w-full"
                    autoFocus
                  />
                ) : (
                  <p
                    className="text-[#B7B7B7] cursor-pointer"
                    onDoubleClick={() => {
                      setEditingAnswerId(question.id);
                      setNewAnswerText(question.answer);
                      setTimeout(
                        () =>
                          answerInputRef.current &&
                          answerInputRef.current.focus(),
                        0
                      );
                    }}
                  >
                    <strong>A:</strong> {question.answer}
                  </p>
                )}
              </div>
              <Delete
                className="delete-icon text-[#B7B7B7]"
                onClick={() => handleDeleteQuestion(question.id)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StationCard;
