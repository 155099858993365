// SignUp.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import './SignUp.css';
import LandingHeader from './LandingHeader'; // Import the extracted header component
import { BASE_URL } from './config';
import Footer from './Footer'; // Import the extracted footer component

const stripePromise = loadStripe('your-publishable-key-here');

const SignUpForm = () => {
  const [name, setName] = useState(''); // New state variable for the name
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleTokenizeResponse = async (token, buyer) => {
    setIsProcessing(true);
    const cleanedToken = token.token.split(':').pop();
    console.log(cleanedToken); // Output: "theActualToken"
    console.log('Tokenize response init1:', token);
    try {
      const response = await fetch(`${BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,  // Include the name in the request body
          email,
          password,
          sourceId: token.token,
          amount: 2400, // Replace with actual amount in cents
        }),
      });
      const data = await response.json();
      console.log("Data : ", data);
      if (data.error) {
        setError(`Registration failed: ${data.error}`);
        setIsProcessing(false);
      } else {
        setIsSuccessful(true);
        setIsProcessing(false);
      }
    } catch (error) {
      console.error('Error processing registration:', error);
      setError('Registration and payment processing failed. Please try again.');
      setIsProcessing(false);
    }
  };

  return (
    <div className="sign-up-page panel-scroll">
      <LandingHeader /> {/* Include the header from the landing page */}
      <div className="sign-up-form-container">
        <div className="sign-up-form">
          {isSuccessful ? (
            <div className="notice">
              <p>Thank you for signing up! We will contact you within 24 hours with the next steps. This will include:</p>
              <ul>
                <li>Information on when you can access the beta</li>
                <li>How to schedule your 15-minute call with the CEO</li>
                <li>Details on accessing onboarding calls</li>
                <li>Instructions on how the beta testing process works</li>
              </ul>
              <p>We appreciate your support and look forward to your feedback!</p>
            </div>
          ) : (
            <div>
              <h2>Sign Up</h2>
              <p>Please provide your details to create an account and join our beta program. Make sure to enter a valid email address as it will be used for all communications.</p>
              <p>You will be charged £24, which is approximately $30. This price gives you $10 of AI tokens and free lifetime access to the platform (excluding AI fees).</p>
              <label>
                Full Name:
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <label>
                Email (ensure this is correct as it will be used for login and notifications):
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <label>
                Password (create a password for your account):
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </label>
              {error && <div className="error">{error}</div>}
              <Elements stripe={stripePromise}>
                <CheckoutForm plan="beta" />
              </Elements>
            </div>
          )}
        </div>
        <Footer /> {/* Add this line */}
      </div>
    </div>
  );
};

const SignUp = () => <SignUpForm />;

export default SignUp;
