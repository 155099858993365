import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './PaymentPage.css';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import { BASE_URL } from './config';
import CardInput from './CardInput';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

const plans = {
  free: { name: 'Free', price: 0 },
  silver: { name: 'Silver', price: 599 },
  gold: { name: 'Gold', price: 999 },
};

const PaymentPage = () => {
  const { plan } = useParams();
  const selectedPlan = plans[plan.toLowerCase()];
  const [name, setName] = useState(''); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(''); 
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const commonStyles = {
    input: { color: '#ffffff' }, // Input text color
    '.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#6b6b6b', // Border color
      },
      '&:hover fieldset': {
        borderColor: '#ffffff', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff', // Border color when focused
      },
    },
  };
const handleFreePlanRegistration = async () => {
  try {
    await fetch(`${BASE_URL}/register-free`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        plan: selectedPlan,
      }),
    });
    navigate(`/payment-confirmation?result=succeeded&plan=free`);
  } catch (error) {
    setErrorMessage('An unexpected error occurred during registration. Please try again.');
  } finally {
    setLoading(false);
  }
};
  const handleSubmitSub = async (event) => {
    event.preventDefault(); 
if (selectedPlan.name === 'Free') {
  await handleFreePlanRegistration();
  return;
}
    if (!stripe || !elements) {
      return; 
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    setLoading(true); 
    setErrorMessage(''); 

    try {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email: email,
        },
      });

      if (result.error) {
        setErrorMessage(result.error.message);
      } else {
        const res = await fetch(`${BASE_URL}/create-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            payment_method: result.paymentMethod.id,
            email: email,
            plan: selectedPlan,
          }),
        });
        const data = await res.json();

        const { client_secret, status, payment_intent_id } = data;

        if (status === 'requires_action') {
          const confirmationResult = await stripe.confirmCardPayment(client_secret);
          if (confirmationResult.error) {
            setErrorMessage('There was an issue with confirming your payment. Please try again.');
          } else if (confirmationResult.paymentIntent.status === 'succeeded') {
            await fetch(`${BASE_URL}/register`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                paymentIntentId: payment_intent_id,
                name: name,
                email: email,
                password: password,
                plan: selectedPlan,
              }),
            });
            navigate(`/payment-confirmation?result=succeededplan=${selectedPlan.name.toLowerCase()}`);
          }
        } else if (status === 'succeeded') {
          await fetch(`${BASE_URL}/register`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              paymentIntentId: payment_intent_id,
              name: name,
              email: email,
              password: password,
              plan: selectedPlan,
            }),
          });
            navigate(`/payment-confirmation?result=succeededplan=${selectedPlan.name.toLowerCase()}`);
        } else {
          setErrorMessage('Payment did not succeed.');
        }
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="landing-page">
      <LandingHeader />
      <section className="panel content-container">
        <h1 className="section-title">
          {selectedPlan.name === 'Free' ? 'Join the Free Plan' : `Payment for ${selectedPlan.name} Plan`}
        </h1>
        {selectedPlan.name !== 'Free' && (
          <p className="price">${(selectedPlan.price / 100).toFixed(2)} per month</p>
        )}
        <Card
          sx={{
            backgroundColor: '#1e1e1e',
            color: '#ffffff',
            padding: '20px',
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >
          <CardContent>
            <form onSubmit={handleSubmitSub}>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>} 
              <p style={{ color: '#aab7c4', marginBottom: '5px', textAlign: 'left' }}>Name</p>
              <TextField
                id="outlined-name-input"
                margin="normal"
                variant="outlined"
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                sx={commonStyles}
              />
              <p style={{ color: '#aab7c4', marginBottom: '5px', textAlign: 'left' }}>Email</p>
              <TextField
                id="outlined-email-input"
                margin="normal"
                variant="outlined"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                sx={commonStyles}
              />
              <p style={{ color: '#aab7c4', marginBottom: '5px', textAlign: 'left'}}>Password</p>
              <TextField
                id="outlined-password-input"
                margin="normal"
                variant="outlined"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                sx={commonStyles}
              />
              <p style={{ color: '#aab7c4', marginBottom: '5px', textAlign: 'left' }}>Confirm Password</p>
              <TextField
                id="outlined-confirm-password-input"
                margin="normal"
                variant="outlined"
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                sx={commonStyles}
              />
              {selectedPlan.name !== 'Free' && <CardInput />}
              <div style={{ marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  endIcon={loading && <CircularProgress size={20} />}
                >
    {loading ? 'Processing...' : (selectedPlan.name === 'Free' ? 'Join Now' : 'Start Subscription')}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </section>
      <Footer />
    </div>
  );
};

export default PaymentPage;
