import React from 'react';
import LandingHeader from './LandingHeader';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <LandingHeader />
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>Effective Date: June 24, 2024</p>

        <p>
          Welcome to Mind Raise. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at privacy@mindraise.com.
        </p>

        <h2>Information We Collect</h2>
        <p>
          We collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining information about us or our products and services, when you participate in activities on the website, or otherwise when you contact us.
        </p>
        <p>The personal information we collect can include the following:</p>
        <ul>
          <li>Name and Contact Data: We collect your first and last name, email address, postal address, phone number, and other similar contact data.</li>
          <li>Credentials: We collect passwords, password hints, and similar security information used for authentication and account access.</li>
          <li>Payment Data: We collect data necessary to process your payment if you make purchases, such as your payment instrument number (e.g., a credit card number), and the security code associated with your payment instrument.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>To facilitate account creation and logon process.</li>
          <li>To send administrative information to you.</li>
          <li>To fulfill and manage your orders.</li>
          <li>To deliver and facilitate the delivery of services to the user.</li>
          <li>To respond to user inquiries/offer support to users.</li>
          <li>To send you marketing and promotional communications.</li>
        </ul>

        <h2>Will Your Information Be Shared With Anyone?</h2>
        <p>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
        <ul>
          <li>We may process or share data based on the following legal basis:</li>
          <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
          <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
          <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
          <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
        </ul>

        <h2>How Do We Keep Your Information Safe?</h2>
        <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>

        <h2>Your Privacy Rights</h2>
        <p>In some regions (like the European Economic Area), you have rights that allow you greater access to and control over your personal information. These rights may include:</p>
        <ul>
          <li>The right to request access and obtain a copy of your personal information.</li>
          <li>The right to request rectification or erasure.</li>
          <li>The right to restrict the processing of your personal information.</li>
          <li>If applicable, the right to data portability.</li>
          <li>In certain circumstances, you may also have the right to object to the processing of your personal information.</li>
        </ul>

        <h2>Changes to This Policy</h2>
        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>

        <h2>Contact Us</h2>
        <p>If you have questions or comments about this policy, you may email us at privacy@mindraise.io </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
