import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Delete, Edit } from "@mui/icons-material";
import { BASE_URL } from "./config";
import urlImage from "./assets/url.png";
import textImage from "./assets/text.png";
import defaultImage from "./assets/image.png";
import pdfImage from "./assets/pdf.png";
import { marked } from "marked";
import YesNoDialog from "./YesNoDialog";
import { fetchWithValidToken } from "./utils";

const defaultImages = {
  url: urlImage,
  text: textImage,
  image: defaultImage,
  pdf: pdfImage,
};

const NodeResourcesPanel = ({ node, onSelectResource }) => {
  const [resources, setResources] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isMounted, setIsMounted] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState(null);

  useEffect(() => {
    setIsMounted(true);

    // Fetch resources or any other data if needed
    if (node && node.resources) {
      setResources(node.resources);
    }

    return () => {
      setIsMounted(false);
    };
  }, [node]);

  const [showForm, setShowForm] = useState(false);
  const [resourceType, setResourceType] = useState("");
  const [resourceData, setResourceData] = useState({ name: "", content: "" });
  const [file, setFile] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleAddResourceClick = (type) => {
    setResourceType(type);
    setShowForm(true);
    setEditorState(EditorState.createEmpty());
    setDropdownVisible(false);
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    setResourceData({
      ...resourceData,
      content: JSON.stringify(convertToRaw(state.getCurrentContent())),
    });
  };

  const handleTypeChange = (e) => {
    setResourceType(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResourceData({ ...resourceData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDelete = async (resourceId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetchWithValidToken(
        `${BASE_URL}/api/nodes/${resourceId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setResources(resources.filter((resource) => resource.id !== resourceId));
    } catch (error) {
      console.error("Error deleting resource:", error);
    }
  };

  const handleEdit = (resource) => {
    let contentState;

    try {
      // Try to parse the content as JSON
      const content = JSON.parse(resource.content);
      contentState = convertFromRaw(content);
    } catch (error) {
      // If parsing fails, assume content is plain text or HTML
      contentState = convertFromRaw({
        entityMap: {},
        blocks: [
          {
            text: resource.content, // treat content as plain text or HTML
            key: "initial",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      });
    }

    setEditorState(EditorState.createWithContent(contentState));

    setResourceData({
      name: resource.name,
      content: resource.content, // retain original content
      id: resource.id,
    });
    setResourceType(resource.node_type);
    setShowForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("name", resourceData.name);
    const nodeType = resourceType === "audio_trans" ? "text" : resourceType;
    formData.append("node_type", nodeType);
    formData.append("parent_id", node.id);
    formData.append("tree_id", node.tree_id);

    if (
      resourceType === "text" ||
      resourceType === "url" ||
      resourceType === "pdf"
    ) {
      formData.append("content", resourceData.content);
    } else {
      formData.append("content", "");
    }

    if (file && resourceType !== "audio_trans") {
      formData.append("file", file);
    }

    const method = resourceData.id ? "PUT" : "POST";
    const url = resourceData.id
      ? `${BASE_URL}/api/nodes/${resourceData.id}`
      : `${BASE_URL}/api/nodes`;

    try {
      const newNode = await fetchWithValidToken(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      // If the resource already exists, update it in the resources array
      if (resourceData.id) {
        setResources(
          resources.map((resource) =>
            resource.id === resourceData.id ? newNode : resource
          )
        );
      } else {
        setResources([...resources, newNode]);
      }

      handleCancel();

      if (resourceType === "audio_trans") {
        await sendForTranscription(newNode.id, file);
      }
    } catch (error) {
      console.error("Error creating resource:", error);
    }
  };

  const sendForTranscription = async (nodeId, audioFile) => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("audio", audioFile);
    formData.append("resourceId", nodeId);

    try {
      const result = await fetchWithValidToken(`${BASE_URL}/api/transcribe`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      console.log("Transcription job started:", result.job_id);
    } catch (error) {
      console.error("Error sending audio for transcription:", error);
    }
  };

  const handleCancel = () => {
    setShowForm(false);
    setResourceType("");
    setResourceData({ name: "", content: "" });
    setFile(null);
  };

  const handleResourceClick = async (resource) => {
    const token = localStorage.getItem("token");
    try {
      const updatedResource = await fetchWithValidToken(
        `${BASE_URL}/api/nodes/${resource.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setResources((prevResources) =>
        prevResources.map((res) =>
          res.id === updatedResource.id ? updatedResource : res
        )
      );

      if (onSelectResource) {
        onSelectResource(updatedResource);
      }
    } catch (error) {
      console.error("Error fetching resource:", error);
    }
  };

  const handleDeleteClick = (resourceId) => {
    setResourceToDelete(resourceId);
    setIsDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!resourceToDelete) return;

    const token = localStorage.getItem("token");
    try {
      const response = await fetchWithValidToken(
        `${BASE_URL}/api/nodes/${resourceToDelete}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setResources(
        resources.filter((resource) => resource.id !== resourceToDelete)
      );
      setResourceToDelete(null);
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error deleting resource:", error);
    }
  };

  return (
    <div className="node-resources-panel text-[#B7B7B7] bg-black text-white h-full pr-1 max-h-screen w-full flex flex-col mb-2 overflow-hidden">
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-between items-center rounded-lg  w-full border-t border-r border-gray-400 shadow-glow p-4 mb-5">
          <h2 className="text-[#B7B7B7] ml-2 text-1xl font-lustria">
            {node.name.toUpperCase()} RESOURCES
          </h2>
          <div className="relative">
            <button
              className="bg-blue-600 text-white py-1 px-2 rounded-lg "
              onClick={() => setDropdownVisible(!dropdownVisible)}
            >
              Add Resource
            </button>

            {dropdownVisible && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 border border-gray-700 rounded-lg shadow-lg z-10">
                <ul>
                  <li
                    className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                    onClick={() => handleAddResourceClick("url")}
                  >
                    URL
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                    onClick={() => handleAddResourceClick("text")}
                  >
                    Text
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                    onClick={() => handleAddResourceClick("image")}
                  >
                    Image
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                    onClick={() => handleAddResourceClick("pdf")}
                  >
                    PDF
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                    onClick={() => handleAddResourceClick("audio_trans")}
                  >
                    Audio Transcription
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {!showForm && (
        <div className="flex-grow overflow-auto bg-gray-900 p-3 rounded-lg mb-4 border border-gray-700 shadow-inner-custom flex flex-col">
          {resources.length === 0 ? (
            <p>No resources found.</p>
          ) : (
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
              {resources.map((resource) => (
                <li
                  key={resource.id}
                  className="relative flex flex-col items-center bg-gray-800 text-white p-4 rounded-lg break-words cursor-pointer"
                  onClick={() => handleResourceClick(resource)}
                >
                  <h3 className="text-center mb-2 pl-1 pr-1">
                    {resource.name}
                  </h3>
                  <img
                    src={defaultImages[resource.node_type]}
                    alt={resource.name}
                    className="w-16 h-16 object-cover rounded mb-2"
                  />
                  <div className="absolute bottom-2 right-2 flex space-x-2">
                    <button
                      className="text-gray-500 hover:text-gray-300"
                      onClick={() => handleEdit(resource)}
                    >
                      <Edit />
                    </button>
                    <button
                      className="text-gray-500 hover:text-gray-300"
                      onClick={() => handleDeleteClick(resource.id)}
                    >
                      <Delete />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {showForm && (
        <form
          style={{ overflowY: "auto" }}
          onSubmit={handleSubmit}
          className="bg-gray-800 p-4 rounded-lg panel-scroll"
        >
          <div className="mb-4">
            <label className="block mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={resourceData.name}
              onChange={handleInputChange}
              className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Resource Type</label>
            <select
              value={resourceType}
              onChange={handleTypeChange}
              className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600"
              required
            >
              <option value="">Select type</option>
              <option value="url">URL</option>
              <option value="text">Text</option>
              <option value="image">Image</option>
              <option value="pdf">PDF</option>
              <option value="audio_trans">Audio Transcription</option>
            </select>
          </div>
          {resourceType === "url" && (
            <div className="mb-4">
              <label className="block mb-2">URL</label>
              <input
                type="text"
                name="content"
                value={resourceData.content}
                onChange={handleInputChange}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600"
              />
            </div>
          )}
          {resourceType === "text" && (
            <div className="editor-container mb-4">
              <label className="block mb-2">Text</label>
              <div
                className="editor-content"
                style={{ maxHeight: "100%", overflowY: "auto" }}
              >
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  toolbarClassName="editor-toolbar"
                  onEditorStateChange={handleEditorChange}
                  toolbar={{
                    inline: {
                      bold: { className: "demo-option-custom" },
                      italic: {
                        className: "demo-option-custom",
                      },
                      underline: {
                        className: "demo-option-custom",
                      },
                      strikethrough: {
                        className: "demo-option-custom",
                      },
                      monospace: { className: "demo-option-custom" },
                      superscript: {
                        className: "demo-option-custom",
                      },
                      subscript: {
                        className: "demo-option-custom",
                      },
                    },
                    blockType: {
                      className: "demo-option-custom-wide",
                      dropdownClassName: "demo-dropdown-custom",
                    },
                    fontSize: { className: "demo-option-custom-medium" },
                    list: {
                      unordered: {
                        className: "demo-option-custom",
                      },
                      ordered: {
                        className: "demo-option-custom",
                      },
                      indent: {
                        className: "demo-option-custom",
                      },
                      outdent: {
                        className: "demo-option-custom",
                      },
                    },
                    textAlign: {
                      left: { className: "demo-option-custom" },
                      center: {
                        className: "demo-option-custom",
                      },
                      right: {
                        className: "demo-option-custom",
                      },
                      justify: {
                        className: "demo-option-custom",
                      },
                    },
                    fontFamily: {
                      className: "demo-option-custom-wide",
                      dropdownClassName: "demo-dropdown-custom",
                    },
                    colorPicker: {
                      className: "demo-option-custom",
                      popupClassName: "demo-popup-custom",
                    },
                  }}
                />
              </div>
            </div>
          )}
          {resourceType === "image" && (
            <div className="mb-4">
              <label className="block mb-2">Upload Image</label>
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600"
              />
            </div>
          )}
          {resourceType === "pdf" && (
            <div className="mb-4">
              <label className="block mb-2">PDF URL</label>
              <input
                type="text"
                name="content"
                value={resourceData.content}
                onChange={handleInputChange}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600"
              />
            </div>
          )}
          {resourceType === "audio_trans" && (
            <div className="mb-4">
              <label className="block mb-2">Upload Audio</label>
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600"
                accept="audio/*"
              />
            </div>
          )}
          <div className="flex justify-between">
            <button
              type="submit"
              className="bg-blue-600 text-white py-1 px-4 rounded-lg"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="bg-red-600 text-white py-1 px-4 rounded-lg"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      <YesNoDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this resource? This action cannot be undone."
      />
    </div>
  );
};

export default NodeResourcesPanel;
