import React, { useState, useEffect } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import './index.css';
import { BASE_URL } from './config';

const TestStationCard = ({ station, testMode }) => {
  const [expanded, setExpanded] = useState(testMode === 'questions');
  const [questions, setQuestions] = useState([]);
  const [expandedQuestions, setExpandedQuestions] = useState({});
  useEffect(() => {
  // Collapse all sections when the test mode changes
  setExpanded(false);
  setExpandedQuestions({});
}, [testMode]);

  useEffect(() => {
    if (testMode === 'questions') {
      const fetchQuestions = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/api/questions?station_id=${station.id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          const data = await response.json();
          if (Array.isArray(data)) {
            setQuestions(data);
            const initialExpandedState = {};
            data.forEach((_, index) => {
              initialExpandedState[index] = false; // Set to false to keep questions collapsed by default
            });
            setExpandedQuestions(initialExpandedState);
          } else {
            console.error('Unexpected data format:', data);
            setQuestions([]);
          }
        } catch (error) {
          console.error('Error fetching questions:', error);
          setQuestions([]);
        }
      };

      fetchQuestions();
    }
  }, [station.id, testMode]);

  const handleExpandClick = () => {
    setExpanded(!expanded);

    if (!expanded) {
      setTimeout(() => {
        const currentElement = document.getElementById(`test-station-card-${station.position}`);
        const nextElement = document.getElementById(`test-station-card-${station.position + 1}`);
        
        if (nextElement) {
          currentElement.scrollIntoView({ behavior: 'smooth' });
        } else if (currentElement) {
          currentElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300); // Adjust timing to match your animation duration
    }
  };

  const toggleQuestionExpand = (questionId) => {
    setExpandedQuestions(prevState => ({
      ...prevState,
      [questionId]: !prevState[questionId]
    }));
  };

  const renderQuestions = () => {
    if (testMode !== 'questions') return null;

    return (
      <div>
        <h3 className="text-lg font-lustria" style={{ color: '#FFD700' }}>Questions</h3> {/* Golden color for the title */}
        {questions.length > 0 ? (
          questions.map((question, index) => (
            <div key={index} className="mb-2">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleQuestionExpand(index)}>
                <p style={{ color: '#87CEEB' }}>{question.question}</p> {/* Sky blue color for the questions */}
                {expandedQuestions[index] ? <ExpandLess style={{ color: '#87CEEB' }} /> : <ExpandMore style={{ color: '#87CEEB' }} />}
              </div>
              {expandedQuestions[index] && (
                <p className="mt-2" style={{ color: '#87CEEB' }}><strong>Answer:</strong> {question.answer}</p>
              )}
            </div>
          ))
        ) : (
          <p style={{ color: '#B7B7B7' }}>No questions available</p>
        )}
      </div>
    );
  };

  const renderAdditionalContent = () => {
    switch (testMode) {
      case 'story':
        return (
          <>
            <h3 className="text-lg font-lustria text-[#B7B7B7]">{station.storyTitle}</h3>
            <p className="text-[#B7B7B7]"><strong>Story:</strong> {station.story}</p>
          </>
        );
      case 'fact':
        return (
          <>
            <h3 className="text-lg font-lustria text-[#B7B7B7]">{station.factTitle}</h3>
            <p className="text-[#B7B7B7]"><strong>Fact:</strong> {station.fact}</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div id={`test-station-card-${station.position}`} className="station-card bg-[#0f0f0f] p-4 rounded-lg shadow-md mb-4 shadow-inner-custom border-t border-r border-gray-400 shadow-glow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-lustria text-[#B7B7B7]">{`${station.position}.`}</h3>
      </div>
      {expanded && (
        <div className="flex">
          {station.photo_url && (
            <div className="w-1/4 pr-4">
              <Zoom>
                <img src={station.photo_url} alt="station" className="custom-zoom-image rounded-md" style={{ width: '100%', objectFit: 'cover' }} />
              </Zoom>
            </div>
          )}
          <div className="w-3/4">
            <h3 className="text-lg font-lustria text-[#B7B7B7]">{station.name}</h3>
            <p className="text-[#B7B7B7]"><strong>Description:</strong> {station.description}</p>
            {renderAdditionalContent()}
            {renderQuestions()}
          </div>
        </div>
      )}
      {testMode !== 'questions' && (
        <button onClick={handleExpandClick} className="text-[#B7B7B7] mt-2 underline">
          {expanded ? 'Minimize' : 'Expand'}
        </button>
      )}
    </div>
  );
};

export default TestStationCard;
