import React, { useState, useEffect } from "react";
import urlImage from "./assets/url.png";
import textImage from "./assets/text.png";
import defaultImage from "./assets/image.png";
import pdfImage from "./assets/pdf.png";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import { fetchWithValidToken } from "./utils";

import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BASE_URL } from "./config";

const defaultImages = {
  url: urlImage,
  text: textImage,
  image: defaultImage,
  pdf: pdfImage,
};

const SelectedResourcePanel = ({ resource }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {
    if (resource && resource.node_type === "text") {
      let contentState;
      try {
        // Assuming resource.content is a raw Draft.js JSON string
        const rawContent = JSON.parse(resource.content);
        contentState = convertFromRaw(rawContent);
      } catch (error) {
        // Fallback to plain text if JSON parsing fails
        contentState = ContentState.createFromText(resource.content || "");
      }
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [resource]);

  if (!resource) {
    return <div>No resource selected.</div>;
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const saveResource = async () => {
    const convertedContent = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    const updatedResource = {
      ...resource,
      content: convertedContent,
    };

    const response = await fetchWithValidToken(
      `${BASE_URL}/api/nodes/${resource.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedResource),
      }
    );
    console.log(resource);

    setIsEditMode(false); // Exit edit mode
  };

  return (
    <div className="h-[100%]">
      <div className="flex flex-col items-center h-full w-full">
        {resource.node_type === "text" && (
          <div
            className="text-content bg-gray-900 p-3 rounded-lg mb-4 border border-gray-700 shadow-inner-custom flex-grow w-full h-full flex flex-col"
            style={{ overflowY: "auto" }}
          >
            <h2 className="text-1xl font-lustria mb-4">
              SELECTED RESOURCE - {resource.name.toUpperCase()}
            </h2>
            <div className="flex-grow overflow-y-auto">
              <Editor
                editorState={editorState}
                toolbarHidden={!isEditMode} // Show toolbar only in edit mode
                readOnly={!isEditMode}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                toolbarClassName="editor-toolbar"
                onEditorStateChange={handleEditorChange}
                toolbar={{
                  inline: {
                    bold: { className: "demo-option-custom" },
                    italic: {
                      className: "demo-option-custom",
                    },
                    underline: {
                      className: "demo-option-custom",
                    },
                    strikethrough: {
                      className: "demo-option-custom",
                    },
                    monospace: { className: "demo-option-custom" },
                    superscript: {
                      className: "demo-option-custom",
                    },
                    subscript: {
                      className: "demo-option-custom",
                    },
                  },
                  blockType: {
                    className: "demo-option-custom-wide",
                    dropdownClassName: "demo-dropdown-custom",
                  },
                  fontSize: { className: "demo-option-custom-medium" },
                  list: {
                    unordered: {
                      className: "demo-option-custom",
                    },
                    ordered: {
                      className: "demo-option-custom",
                    },
                    indent: {
                      className: "demo-option-custom",
                    },
                    outdent: {
                      className: "demo-option-custom",
                    },
                  },
                  textAlign: {
                    left: { className: "demo-option-custom" },
                    center: {
                      className: "demo-option-custom",
                    },
                    right: {
                      className: "demo-option-custom",
                    },
                    justify: {
                      className: "demo-option-custom",
                    },
                  },
                  fontFamily: {
                    className: "demo-option-custom-wide",
                    dropdownClassName: "demo-dropdown-custom",
                  },
                  colorPicker: {
                    className: "demo-option-custom",
                    popupClassName: "demo-popup-custom",
                  },
                }}
                editorStyle={{
                  minHeight: "200px",
                  maxHeight: "100%",
                  overflowY: "auto",
                }}
              />
            </div>
            <div className="flex flex-row mt-4">
              <button
                className="bg-blue-600 text-white py-1 px-2 rounded-lg mr-2"
                onClick={toggleEditMode}
              >
                {isEditMode ? "Cancel" : "Edit"}
              </button>
              {isEditMode && (
                <button
                  className="bg-blue-600 text-white py-1 px-2 rounded-lg"
                  onClick={saveResource}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        )}
        {resource.node_type === "url" && (
          <a
            href={resource.content}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-200"
          >
            {resource.content}
          </a>
        )}
        {resource.node_type === "image" && (
          <img
            src={resource.content}
            alt={resource.name}
            className="max-w-full h-auto rounded-lg"
          />
        )}
        {resource.node_type === "pdf" && (
          <a
            href={resource.content}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-200"
          >
            View PDF
          </a>
        )}
      </div>
    </div>
  );
};

export default SelectedResourcePanel;
